import React from 'react';

import BaseSelectorSlicer from '../components/BaseSelectorSlicer';
import { slicerPropTypes } from '../slicerPropTypes';

import defaultVipStatus from '../../../models/slicers/defaultVipStatus.json';

const options = Object.keys(defaultVipStatus.queries).map((key) => ({
  text: key,
  value: key,
}));

const DefaultVipStatusSlicer = ({ id, value, onChange, title }) => (
  <BaseSelectorSlicer id={id} value={value} onChange={onChange} options={options} title={title} />
);

DefaultVipStatusSlicer.propTypes = slicerPropTypes;

DefaultVipStatusSlicer.defaultProps = {
  id: 'User Subscription Tier',
  onChange: () => {},
};

export default DefaultVipStatusSlicer;
