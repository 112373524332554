import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { LoadingLogo } from '../../components/Modal';
import AccountDisplay from './components/AccountDisplay';
import EditableParam from './components/EditableParam';
import FestivalProgressDisplay from './components/FestivalProgressDisplay';
import InfluencerDisplay from './components/InfluencerDisplay';
import ItemsAdd from './components/ItemsAdd';
import ItemsEditor from './components/ItemsEditor';
import LoyaltyDisplay from './components/LoyaltyDisplay';
import MonthlyMissionsDisplay from './components/MonthlyMissionsDisplay';
import MonthlyEditor from './components/MonthlyEditor';
import PiggyEditor from './components/PiggyEditor';
import MusicFragmentAdd from './components/MusicFragmentAdd';
import MusicFragmentEditor from './components/MusicFragmentEditor';
import PlaylistEditor from './components/PlaylistEditor';
import SecretContentPackIdsDisplay from './components/SecretContentPackIdsDisplay';
import TransactionDisplay from './components/TransactionDisplay';
import LiveShowDisplay from './components/LiveShowDisplay';
import Sp2LinkDisplay from './components/Sp2LinkDisplay';
import UserReportDisplay from './components/UserReportDisplay';
import { EDITABLE_PARAMS, ParamTypeValue, PARAM_TYPES, useUserView } from './useUserView';
import NotificationToolDisplay from './components/NotificationToolDisplay';
import AchievementMissionsDisplay from './components/AchievementMissionsDisplay';
import RetentionToolDisplay from './components/RetentionToolDisplay';
import { HurdleQuizDisplay } from './components/HurdleQuizDisplay';
import { UserSubscriptionLogsDisplay } from './components/UserSubscriptionLogDisplay';
import { CacheDisplay } from './components/CacheDisplay';
import YearInReviewDisplay from './components/YearInReviewDisplay';
import MetaFragmentsEditor from './components/MetaFragmentsEditor';
import UserTeamDisplay from './components/UserTeamDisplay';

const baseURL = '/sp3/devtools/userview';

const UserView = () => {
  const {
    handleInputChange,
    handleChangeUser,
    handleSearch,
    handleSaveUser,
    handleBanUser,
    handleUnbanUser,
    handleResetUserSubscriptions,
    handleForceABTest,
    handleSearchUrl,
    handleChangeMonthlyPass,
    handleChangePiggyBank,
    handleAddItem,
    handleAddAllItems,
    handleDeleteItem,
    handleChangeItemLevel,
    updateLoading,
    newUser,
    contentCategories,
    energyCaps,
    stickers,
    profileFrames,
    appSkins,
    data,
    input,
    handleChangeEarnedContentPackIds,
    handleChangeBasicPlaylist,
    handleChangeFragment,
    updateStatus,
    searchUserCalled,
    getUserLoading,
    abTestConfigs,
    appInfoLoading,
    lastSession,
    handleKeyPressed,
    resetSubscriptionsLoading,
    handleChangeMetaFragments,
    handleChangeShards,
  } = useUserView();
  const { userid } = useParams<{ userid: string }>();
  const history = useHistory();

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    // TODO : try to see why the route send us by default to 'userview/:userid'
    if (userid && userid !== ':userid') {
      handleSearchUrl(userid);
    } else if (userid && userid === ':userid') {
      history.push(baseURL);
    }
  }, [history, userid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data && newUser) {
      history.push(`${baseURL}/${data.id}`);
    }
  }, [data, history, newUser]);
  const categories = newUser ? _.differenceBy(contentCategories, newUser.fragmentBalance, 'id') : [];
  return (
    <>
      <Card className="shadow mb-4">
        <Card.Body>
          <Row>
            <Col>
              <Form>
                <Form.Group as={Row} className="d-flex justify-content-around">
                  <Col sm="9">
                    <Form.Control
                      value={input}
                      placeholder="Enter ID, name, email or phone number"
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPressed}
                    />
                  </Col>
                  <Button onClick={handleSearch}>Search</Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <LoadingLogo show={getUserLoading || updateLoading} />
      {data && newUser && abTestConfigs ? (
        <>
          <AccountDisplay
            account={data}
            abTestConfigsData={abTestConfigs}
            lastSession={lastSession}
            onForceTest={(testId: string, variation: string) => handleForceABTest(userid, testId, variation)}
            forceTestLoading={false}
            onBanUser={() => handleBanUser(userid)}
            onUnbanUser={() => handleUnbanUser(userid)}
            onResetUserSubscriptions={() => handleResetUserSubscriptions(userid)}
            resetSubscriptionsLoading={resetSubscriptionsLoading}
          />
          <LoyaltyDisplay userId={userid} loyalty={data.loyalty} />
          <HurdleQuizDisplay userId={userid} />
          <UserSubscriptionLogsDisplay userId={userid} />
          {/* @ts-ignore */}
          <AchievementMissionsDisplay userId={userid} missions={data.achievementMissions?.missions} />
          {/* @ts-ignore */}
          <MonthlyMissionsDisplay userId={userid} missions={data.currentUserMonthlyPass?.missions} />
          <SecretContentPackIdsDisplay
            onChange={handleChangeEarnedContentPackIds}
            earnedContentPacks={data.ownedContentPacks.list}
          />
          <FestivalProgressDisplay
            userId={userid}
            specialEventId={data.currentSpecialEvent?.id}
            scoreDetails={data.currentSpecialEvent?.meScoreDetails}
          />
          {data.reports.length > 0 && <UserReportDisplay reports={data.reports} />}
          <TransactionDisplay userId={userid} />
          <LiveShowDisplay userId={userid} />
          <YearInReviewDisplay userId={userid} />
          <Sp2LinkDisplay userId={userid} />
          <CacheDisplay userId={userid} />
          <InfluencerDisplay
            userId={userid}
            isInfluencer={data.isInfluencer ?? false}
            username={data.displayName ?? ''}
            influencerPicture={data.picture ?? undefined}
          />
          <NotificationToolDisplay userId={userid} />
          <RetentionToolDisplay userId={userid} />
          <UserTeamDisplay userId={userid} teamId={newUser.team.id} />
          <Card className="mb-4">
            <Card.Body>
              <Accordion>
                <Accordion.Toggle as={Card.Title} variant="link" eventKey="0" onClick={() => setExpanded(!expanded)}>
                  <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
                    User Attributes
                    <Button variant="link">
                      {!expanded && <i className="fas fa-chevron-down" />}
                      {expanded && <i className="fas fa-chevron-up" />}
                    </Button>
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <>
                    <Form>
                      <Row>
                        {EDITABLE_PARAMS.map(({ key, type }) => {
                          switch (type as ParamTypeValue) {
                            case PARAM_TYPES.INT:
                            case PARAM_TYPES.FLOAT:
                            case PARAM_TYPES.STRING:
                              return (
                                <Col md="6">
                                  <EditableParam
                                    key={key}
                                    title={key}
                                    // @ts-ignore
                                    value={newUser[key]}
                                    onChange={handleChangeUser(key)}
                                    // @ts-ignore
                                    placeholder={data[key]}
                                  />
                                </Col>
                              );

                            case PARAM_TYPES.ENERGY:
                              return (
                                !appInfoLoading && (
                                  <Col md="12">
                                    <EditableParam
                                      key={key}
                                      title={key}
                                      details={`max: ${
                                        // @ts-ignore
                                        energyCaps[data.subscriptionTier][
                                          key.includes('XP')
                                            ? 'xp'
                                            : key.includes('Coin')
                                            ? 'coin'
                                            : key.includes('Tournament')
                                            ? 'tournamentTicket'
                                            : 'liveEventTicket'
                                        ]
                                      }`}
                                      // @ts-ignore
                                      value={newUser[key]}
                                      onChange={handleChangeUser(key)}
                                      // @ts-ignore
                                      placeholder={data[key]}
                                    />
                                  </Col>
                                )
                              );

                            case PARAM_TYPES.PLAYLIST:
                              return (
                                <Col md="12">
                                  <Form.Group key={key}>
                                    <Form.Label>{key}</Form.Label>
                                    {/* @ts-ignore */}
                                    <PlaylistEditor
                                      // @ts-ignore
                                      playlist={newUser[key]}
                                      // @ts-ignore
                                      title={key}
                                      onChange={handleChangeBasicPlaylist}
                                    />
                                  </Form.Group>
                                </Col>
                              );
                            case PARAM_TYPES.MONTHLY_PASS:
                              // @ts-ignore
                              if (newUser.userLevel >= 20 && newUser[key]) {
                                return (
                                  <Col md="12">
                                    <Form.Group key={key}>
                                      <Form.Label>{key}</Form.Label>
                                      {/* @ts-ignore */}
                                      <MonthlyEditor
                                        // @ts-ignore
                                        monthly={newUser[key]}
                                        // @ts-ignore
                                        title={key}
                                        // @ts-ignore
                                        onChange={handleChangeMonthlyPass}
                                      />
                                    </Form.Group>
                                  </Col>
                                );
                              }
                              return null;
                            case PARAM_TYPES.PIGGY_BANK:
                              return (
                                <Col md="12">
                                  <Form.Group key={key}>
                                    <Form.Label>{key}</Form.Label>
                                    <PiggyEditor
                                      // @ts-ignore
                                      bank={newUser[key]}
                                      // @ts-ignore
                                      title={key}
                                      // @ts-ignore
                                      onChange={handleChangePiggyBank}
                                    />
                                  </Form.Group>
                                </Col>
                              );

                            case PARAM_TYPES.PLAYLIST_MULTI:
                              return (
                                <Col md="12">
                                  <Form.Group key={key}>
                                    <Form.Label>{key}</Form.Label>
                                    {/* @ts-ignore */}
                                    {newUser[key].length > 0 ? (
                                      <Row>
                                        {/* @ts-ignore */}
                                        {newUser[key].map((playlist) => (
                                          <Col>
                                            {/* @ts-ignore */}
                                            <PlaylistEditor playlist={playlist} canDelete />
                                          </Col>
                                        ))}
                                      </Row>
                                    ) : (
                                      <p>
                                        <i>No saved playlists</i>
                                      </p>
                                    )}
                                  </Form.Group>
                                </Col>
                              );
                            case PARAM_TYPES.META_FRAGMENTS:
                              return (
                                <Col md="12">
                                  <Form.Group key={key}>
                                    <Form.Label>{key}</Form.Label>
                                    <MetaFragmentsEditor
                                      // @ts-ignore
                                      meta={newUser[key]}
                                      // @ts-ignore
                                      title={key}
                                      // @ts-ignore
                                      onChange={handleChangeMetaFragments}
                                    />
                                  </Form.Group>
                                </Col>
                              );
                            case PARAM_TYPES.SHARDS:
                              return (
                                <Col md="12">
                                  <Form.Group key={key}>
                                    <Form.Label>{key}</Form.Label>
                                    <MetaFragmentsEditor
                                      // @ts-ignore
                                      meta={newUser[key]}
                                      // @ts-ignore
                                      title={key}
                                      // @ts-ignore
                                      onChange={handleChangeShards}
                                    />
                                  </Form.Group>
                                </Col>
                              );
                            case PARAM_TYPES.FRAGMENT:
                              return (
                                <Col md="12">
                                  <Form.Group key={key}>
                                    <div className="d-flex justify-content-between align-items-baseline">
                                      <Form.Label>{key}</Form.Label>
                                      <div className="d-flex">
                                        <MusicFragmentAdd categories={categories} onChange={handleChangeFragment} />
                                      </div>
                                    </div>
                                    {/* @ts-ignore */}
                                    {newUser[key].length > 0 ? (
                                      <Row>
                                        {/* @ts-ignore */}
                                        {newUser[key].map((fragment) => (
                                          <Col key={fragment.id} md="auto" className="mb-4">
                                            {/* @ts-ignore */}
                                            <MusicFragmentEditor
                                              fragment={fragment}
                                              onChange={handleChangeFragment}
                                              contentCategories={contentCategories}
                                            />
                                          </Col>
                                        ))}
                                      </Row>
                                    ) : (
                                      <p>
                                        <i>No fragments</i>
                                      </p>
                                    )}
                                  </Form.Group>
                                </Col>
                              );
                            case PARAM_TYPES.STICKERS:
                              return (
                                <Col md="12">
                                  <Form.Group key={key}>
                                    <div className="d-flex justify-content-between align-items-baseline">
                                      <Form.Label>{key}</Form.Label>
                                      <div className="d-flex">
                                        <ItemsAdd
                                          items={stickers}
                                          type={key}
                                          onAdd={handleAddItem}
                                          onAddAll={handleAddAllItems}
                                        />
                                      </div>
                                    </div>
                                    <ItemsEditor
                                      // @ts-ignore
                                      items={newUser[key]}
                                      type={key}
                                      onDelete={handleDeleteItem}
                                      onChangeItemLevel={handleChangeItemLevel}
                                    />
                                  </Form.Group>
                                </Col>
                              );
                            case PARAM_TYPES.PROFILE_FRAMES:
                              return (
                                <Col md="12">
                                  <Form.Group key={key}>
                                    <div className="d-flex justify-content-between align-items-baseline">
                                      <Form.Label>{key}</Form.Label>
                                      <div className="d-flex">
                                        <ItemsAdd
                                          items={profileFrames}
                                          type={key}
                                          onAdd={handleAddItem}
                                          onAddAll={handleAddAllItems}
                                        />
                                      </div>
                                    </div>
                                    <ItemsEditor
                                      // @ts-ignore
                                      items={newUser[key]}
                                      type={key}
                                      onDelete={handleDeleteItem}
                                      onChangeItemLevel={handleChangeItemLevel}
                                    />
                                  </Form.Group>
                                </Col>
                              );
                            case PARAM_TYPES.APP_SKINS:
                              return (
                                <Col md="12">
                                  <Form.Group key={key}>
                                    <div className="d-flex justify-content-between align-items-baseline">
                                      <Form.Label>{key}</Form.Label>
                                      <div className="d-flex">
                                        <ItemsAdd
                                          items={appSkins}
                                          type={key}
                                          onAdd={handleAddItem}
                                          onAddAll={handleAddAllItems}
                                        />
                                      </div>
                                    </div>
                                    <ItemsEditor
                                      // @ts-ignore
                                      items={newUser[key]}
                                      type={key}
                                      onDelete={handleDeleteItem}
                                      onChangeItemLevel={handleChangeItemLevel}
                                    />
                                  </Form.Group>
                                </Col>
                              );
                            default:
                              return <></>;
                          }
                        })}
                      </Row>
                    </Form>
                    {updateLoading ? (
                      <Spinner animation="border" />
                    ) : (
                      <div className="d-flex justify-content-end align-items-baseline mb-3">
                        <Button className="mx-2" onClick={handleSaveUser}>
                          Save User
                        </Button>
                      </div>
                    )}
                    {updateStatus &&
                      (updateStatus === 'failed' ? (
                        <Alert variant="danger">Update failed</Alert>
                      ) : (
                        <Alert variant="success">Update Succeed</Alert>
                      ))}
                  </>
                </Accordion.Collapse>
              </Accordion>
            </Card.Body>
          </Card>
        </>
      ) : searchUserCalled && !getUserLoading ? (
        <span>
          <i className="fas fa-sad-tear m-2" />
          <i>No user found</i>
        </span>
      ) : (
        <span>
          <i className="fas fa-search m-2" />
          <i>Search for a user</i>
        </span>
      )}
    </>
  );
};

export default UserView;
