import { Card, Button, Modal, Table } from 'react-bootstrap';
import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import DateTimePicker from 'react-datetime';
import Form from 'react-bootstrap/Form';
import EditShopModal from './components/EditShopModal';
import { TeamShopItemRow } from './components/TeamShopItem';
import { ADD_TEAM_SHOP_ITEM, DELETE_TEAM_SHOP_ITEM, GET_TEAM_SHOP, UPDATE_TEAM_SHOP_ITEM } from './graphql';
import {
  CreateUpdateTeamShopItemInput,
  LevelRewardInputType,
  TeamShopBuyLimitEnum,
  TeamShopItem,
} from '../../../../../__gqltypes__';

const TeamShop = () => {
  const [addTeamShopItem] = useMutation(ADD_TEAM_SHOP_ITEM, {
    onCompleted: (data) => {
      if (data?.createTeamShopItem?.list) {
        setShop(data.createTeamShopItem.list);
      }
      setSelectedItem(null);
    },
  });
  const [updateTeamShopItem] = useMutation(UPDATE_TEAM_SHOP_ITEM, {
    onCompleted: (data) => {
      if (data?.updateTeamShopItem?.list) {
        setShop(data.updateTeamShopItem.list);
      }
      setSelectedItem(null);
    },
  });
  const [removeItem] = useMutation(DELETE_TEAM_SHOP_ITEM, {
    onCompleted: (data) => {
      if (data?.deleteTeamShopItem?.list) {
        setShop(data.deleteTeamShopItem.list);
      }
      setItemToRemoveId(null);
    },
  });
  const [itemToRemoveId, setItemToRemoveId] = useState<string | null>(null);
  const [selectedItem, setSelectedItem] = useState<TeamShopItem | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [shop, setShop] = useState([]);
  const [filter, setFilter] = useState<{ endDate: string | null; startDate: string | null; rewardType: string | null }>(
    { endDate: null, startDate: null, rewardType: null }
  );

  const { data: _shopData } = useQuery(GET_TEAM_SHOP, {
    fetchPolicy: 'no-cache',
    variables: {
      limit: null,
      offset: null,
      filter: {
        startDate: filter.startDate === '' ? null : filter.startDate,
        endDate: filter.endDate === '' ? null : filter.endDate,
        rewardType: filter.rewardType === 'Item type' ? null : filter.rewardType,
      },
    },
    onCompleted: (data) => {
      setShop(data?.teamShop?.list ?? []);
    },
  });

  const handleShowEditItem = (item: TeamShopItem | null, isEd: boolean) => {
    setIsEdit(isEd);
    if (!isEd) {
      setSelectedItem({
        startDate: moment().toDate(),
        endDate: null,
        discount: null,
        reward: { type: LevelRewardInputType.CURRENCY_COIN, amount: 0 },
        cost: 0,
        buyLimit: null,
        buyLimitType: TeamShopBuyLimitEnum.UNLIMITED,
      } as TeamShopItem);
    } else {
      setSelectedItem(item);
    }
  };

  const handleSave = (item: CreateUpdateTeamShopItemInput) => {
    if (isEdit) {
      updateTeamShopItem({ variables: { item, itemId: selectedItem?.id } });
    } else {
      addTeamShopItem({ variables: { item } });
    }
  };

  return (
    <Card style={{ width: '100%' }}>
      <Card.Header className="text-left d-flex justify-content-between">
        <div>
          <Card.Title>Shop</Card.Title>
          <Button className="align-self-center ml-1" onClick={() => handleShowEditItem(null, false)}>
            Add item
          </Button>
        </div>
        <div className="d-flex">
          <DateTimePicker
            inputProps={{ placeholder: 'Start date' }}
            dateFormat="YYYY-MM-DD"
            timeFormat={true}
            timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
            // @ts-ignore
            onChange={(startDate) => setFilter({ ...filter, startDate })}
            // @ts-ignore
            value={filter.startDate ?? undefined}
          />
          <DateTimePicker
            inputProps={{ placeholder: 'End date' }}
            dateFormat="YYYY-MM-DD"
            timeFormat={true}
            timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
            // @ts-ignore
            onChange={(endDate) => setFilter({ ...filter, endDate })}
            // @ts-ignore
            value={filter.endDate ?? undefined}
          />
          <Form.Control
            as="select"
            value={filter.rewardType ?? undefined}
            onChange={(e) => setFilter({ ...filter, rewardType: e.target.value ?? null })}
          >
            {['Item type', ...Object.values(LevelRewardInputType)].map((type) => (
              <option value={type} key={type}>
                {type}
              </option>
            ))}
          </Form.Control>
        </div>
      </Card.Header>
      <Card.Body>
        <EditShopModal
          isEdit={true}
          show={!!selectedItem}
          item={selectedItem}
          handleSave={handleSave}
          handleClose={() => setSelectedItem(null)}
          disableCurrencySpecialEvent
          disableMonthlyBuff
        />
        <Table bordered hover>
          <thead>
            <tr>
              <th>Shop Item Id</th>
              <th>Item Type</th>
              <th>Item ID</th>
              <th>Amount</th>
              <th>Cost</th>
              <th>Discount</th>
              <th>Buy Limit</th>
              <th>Buy Limit Type</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {shop.map((item: TeamShopItem) => {
              return (
                <TeamShopItemRow
                  key={item.id}
                  item={item}
                  removeAction={() => setItemToRemoveId(item.id)}
                  editAction={() => handleShowEditItem(item, true)}
                />
              );
            })}
          </tbody>
        </Table>
      </Card.Body>

      <Modal show={!!itemToRemoveId} onHide={() => setItemToRemoveId(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete item</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="text-center">Are you sure to want to remove this item {itemToRemoveId}?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setItemToRemoveId(null)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={async () => {
              removeItem({ variables: { itemId: itemToRemoveId } });
            }}
          >
            Remove Item
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default TeamShop;
