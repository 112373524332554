import moment from 'moment';
import { Button } from 'react-bootstrap';
import { TeamShopItem } from '../../../../../../__gqltypes__';

type Props = {
  item: TeamShopItem;
  removeAction: () => void;
  editAction: () => void;
};

export function TeamShopItemRow({ item, removeAction, editAction }: Props) {
  return (
    <tr>
      <td>{item.id}</td>
      <td>{item.reward.type}</td>
      <td>{item.reward.itemId}</td>
      <td>{item.reward.amount}</td>
      <td>{item.cost}</td>
      <td>{item.discount}</td>
      <td>{item.buyLimit}</td>
      <td>{item.buyLimitType}</td>
      <td>{moment(item.startDate).format('YYYY-MM-DD HH:mm')}</td>
      <td>{item.endDate ? moment(item.endDate).format('YYYY-MM-DD HH:mm') : ''}</td>
      <td>
        <div className="d-flex">
          <Button className="mr-1" onClick={editAction}>
            Edit
          </Button>
          <Button variant="danger" onClick={removeAction}>
            Remove
          </Button>
        </div>
      </td>
    </tr>
  );
}
