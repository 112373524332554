import { useCallback, useEffect, useState } from 'react';

import { Button, Col, Form, Row } from 'react-bootstrap';
import { LiveEventShopItemConfig, LevelRewardInput, LevelRewardInputType } from '../../../../../../__gqltypes__';
import ChipShopItem from './ChipShopItem';
import EditShopModal from './EditShopModal';
import { convertLiveEventShopItemToLevelRewardInput } from '../utils';

type Props = {
  shop: LiveEventShopItemConfig[];
  onUpdateShop: (updatedShop: LiveEventShopItemConfig[]) => LiveEventShopItemConfig[];
};

export function LiveEventShop({ shop, onUpdateShop }: Props) {
  const [shop_, setShop] = useState<LiveEventShopItemConfig[]>(shop);
  const [showEditReward, setShowEditReward] = useState(false);
  const [selectedReward, setSelectedReward] = useState<(LevelRewardInput & { cost: number; buyLimit: number }) | null>(
    null
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const [rewardIndex, setRewardIndex] = useState(0);

  useEffect(() => {
    setShop(shop);
  }, [shop.length, shop]);

  useEffect(() => {
    onUpdateShop(shop_);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop_.length, shop_]);

  const handleAddReward = useCallback(
    (newReward: LevelRewardInput & { cost: number; buyLimit: number }) => {
      if (isEditMode) {
        shop_.push({
          __typename: 'LiveEventShopItemConfig',
          type: newReward.type.toString(),
          amount: newReward.amount,
          itemId: newReward.itemId ?? '',
          cost: newReward.cost,
          buyLimit: newReward.buyLimit,
        });
      } else {
        shop_[rewardIndex] = {
          __typename: 'LiveEventShopItemConfig',
          type: newReward.type.toString(),
          amount: newReward.amount,
          itemId: newReward.itemId ?? '',
          cost: newReward.cost,
          buyLimit: newReward.buyLimit,
        };
      }
      setShowEditReward(false);
    },
    [shop_.length, shop_, isEditMode, rewardIndex]
  );

  const handleRemoveReward = useCallback(
    (idx: number) => {
      if (idx >= 0 && idx < shop_.length) {
        setShop(shop_.filter((val, index) => index !== idx));
      }
    },
    [shop_.length, shop_]
  );

  const handleShowEditReward = useCallback(
    (idx: number, isEdit: boolean) => {
      setIsEditMode(isEdit);
      if (isEdit) {
        const rew = shop_[idx];
        const levelReward: LevelRewardInput & { cost: number; buyLimit: number } = {
          amount: rew.amount,
          type:
            Object.values(LevelRewardInputType).find((val) => val === rew.type.toString()) ??
            LevelRewardInputType.CURRENCY_COIN,
          itemId: rew.itemId,
          cost: rew.cost,
          buyLimit: rew.buyLimit,
        };
        setSelectedReward(levelReward);
      } else {
        setSelectedReward(null);
      }
      setRewardIndex(idx);
      setShowEditReward(true);
    },
    [shop_.length, shop_]
  );

  return (
    <>
      <EditShopModal
        isEdit={isEditMode}
        tier={null}
        show={showEditReward}
        level={0}
        reward={selectedReward}
        handleSave={handleAddReward}
        handleClose={() => setShowEditReward(false)}
        disableCurrencySpecialEvent
        disableMonthlyBuff
      />
      <Row className="mb-3">
        <Col className="d-flex flex-column align-items-center">
          <Form.Label className="font-weight-bold">Add shop item</Form.Label>
          <Button variant="success" onClick={() => handleShowEditReward(shop_.length, false)}>
            <i className="fas fa-plus" />
          </Button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="d-flex flex-column align-items-center">
          <Form.Label className="font-weight-bold">Shop Items</Form.Label>
          {shop_.map((reward, index) => {
            return (
              <Row key={index} className="mt-2 align-items-end">
                <ChipShopItem
                  reward={convertLiveEventShopItemToLevelRewardInput(reward)}
                  canDelete
                  btnAction={() => handleRemoveReward(index)}
                  globalAction={() => handleShowEditReward(index, true)}
                  cost={reward.cost}
                  buyLimit={reward.buyLimit}
                />
              </Row>
            );
          })}
        </Col>
      </Row>
    </>
  );
}
