import React from 'react';
import BaseSelectorSlicer from '../components/BaseSelectorSlicer';
import { slicerPropTypes } from '../slicerPropTypes';

import continent from '../../../models/slicers/continent.json';

const options = Object.keys(continent.queries).map((key) => ({
  text: key,
  value: key,
}));

const DefaultContinentSlicer = ({ id, value, onChange, title }) => (
  <BaseSelectorSlicer id={id} value={value} onChange={onChange} options={options} title={title} />
);

DefaultContinentSlicer.propTypes = slicerPropTypes;

DefaultContinentSlicer.defaultProps = {
  id: 'Continent',
  onChange: () => {},
};

export default DefaultContinentSlicer;

