import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { JsonObjectExpression } from 'typescript';

import { 
  CREATE_MESSAGE_TEMPLATE,
  DELETE_MESSAGE_TEMPLATE,
  EDIT_MESSAGE_TEMPLATE,
  GET_MESSAGE_TEMPLATES,
  GET_NUMBER_OF_RECIPIENTS,
  SEND_MASS_GIFT
} from './graphql';
import {
  CreateMessageTemplateMutation,
  CreateMessageTemplateMutationVariables,
  GetAllMessageTemplatesQuery,
  GetAllMessageTemplatesQueryVariables,
  GetNumberOfRecipientsQuery,
  GetNumberOfRecipientsQueryVariables,
  GiftFilters,
  ItemAvailability,
  LevelRewardInput,
  SendMassGiftMutation,
  SendMassGiftMutationVariables,
  MessageTemplate,
  EditMessageTemplateMutation,
  EditMessageTemplateMutationVariables,
  DeleteMessageTemplateMutation,
  DeleteMessageTemplateMutationVariables,
} from '../../../../../__gqltypes__';
import { MassGiftFilters } from './MassGiftFilters';
import EditRewardsModal from '../../../liveops/components/EditRewardsModal';
import Chip from '../../../liveops/components/Chip';
import CostDisplay from '../../../../../utils/dashboards/components/widgets/CostDisplay';
import { LoadingLogo } from '../../components/Modal';


const MassGift = () => {
  const [filters, setFilters] = useState<GiftFilters>({});
  const [gifts, setGifts] = useState<Array<LevelRewardInput>>([]);

  const [isEditMode, setIsEditMode] = useState(false);
  const [showEditGift, setShowEditGift] = useState(false);
  const [selectedGift, setSelectedGift] = useState<LevelRewardInput | null>(null);
  const [giftIndex, setGiftIndex] = useState(0);

  const [numberOfRecipients, setNumberOfRecipients] = useState(0);

  const [availableMsgTemplates, setAvailableMsgTemplates] = useState<MessageTemplate[]>([]);
  const [giftMessage, setGiftMessage] = useState<MessageTemplate | null>(null);
  const [newMsgTemplateInEdit, setNewMsgTemplateInEdit] = useState<string>('');
  const [msgTemplateEditOn, setMsgTemplateEditOn] = useState(false);

  const [metadataList, setMetadataList] = useState<string[]>([]);

  const {data: msgTemplates, loading: msgTemplatesLoading} = useQuery<
    GetAllMessageTemplatesQuery,
    GetAllMessageTemplatesQueryVariables
  >(GET_MESSAGE_TEMPLATES);
  
  const [createMsgTemplate, {loading: createMTLoading}] = useMutation<
    CreateMessageTemplateMutation,
    CreateMessageTemplateMutationVariables
  >(CREATE_MESSAGE_TEMPLATE);

  const [editMsgTemplate, {loading: editMTLoading}] = useMutation<
    EditMessageTemplateMutation,
    EditMessageTemplateMutationVariables
  >(EDIT_MESSAGE_TEMPLATE);

  const [deleteMsgTemplate, {loading: deleteMTLoading}] = useMutation<
    DeleteMessageTemplateMutation,
    DeleteMessageTemplateMutationVariables
  >(DELETE_MESSAGE_TEMPLATE);

  const [getNumberOfRecipients, {data: numberData, loading: numberOfRecipLoading}] = useLazyQuery<
    GetNumberOfRecipientsQuery,
    GetNumberOfRecipientsQueryVariables
  >(GET_NUMBER_OF_RECIPIENTS);

  const [sendMassGift, {data: sentGiftResult, loading: sendGiftsLoading}] = useMutation<
    SendMassGiftMutation,
    SendMassGiftMutationVariables
  >(SEND_MASS_GIFT, {
    onCompleted: (data_) => {
      setMetadataList(data_.sendGiftAdmin.metadataList);
    },
  });

  useEffect(() => {
    if (!msgTemplatesLoading && msgTemplates) {
      setAvailableMsgTemplates(msgTemplates.getMessageTemplates);
    }
  }, [msgTemplates, msgTemplatesLoading]);

  const metadata: Record<string, JsonObjectExpression> = useMemo(() => {
    if (metadataList.length === 0) {
      return {};
    }
    const m: Record<string, JsonObjectExpression> = {};
    metadataList.forEach((val, index) => {
      if (val) {
        m[index.toString()] = JSON.parse(val);
      }
    });
    return m;
  }, [metadataList]);

  const onRefreshNumber = () => {
    getNumberOfRecipients({variables: {
      filters,
    }}).then((value) => {
      const data_ = value.data?.getPotentialNumberOfRecipients;
      setNumberOfRecipients(data_?.count ?? 0);
      setMetadataList(data_?.metadataList ?? []);
    });
  };

  const onSendGifts = () => {
    sendMassGift({variables: {
      gifts,
      filters,
      messageId: giftMessage?.id ?? null,
    }}).then((value) => {
      const data_ = value.data?.sendGiftAdmin;
      setGifts([]);
      setGiftMessage(null);
      setMetadataList(data_?.metadataList ?? []);
    });
  };

  const onChange = useCallback((newVal: GiftFilters) => {
    setFilters(newVal);
  }, []);

  const handleAddGift = useCallback((newReward: LevelRewardInput) => {
    if (isEditMode) {
      const newGifts = [...gifts];
      newGifts[giftIndex] = ({
        type: newReward.type,
        amount: newReward.amount,
        itemId: newReward.itemId ?? '',
      });
      setGifts(newGifts);
    } else {
      const newGifts = [...gifts];
      newGifts.push({
        type: newReward.type,
        amount: newReward.amount,
        itemId: newReward.itemId ?? '',
      });
      setGifts(newGifts);
    }
    setShowEditGift(false);
  }, [isEditMode, gifts, giftIndex]);

  const handleRemoveGift = useCallback((idx: number) => {
    if (idx >= 0 && idx < gifts.length) {
      let newGifts = [...gifts];
      newGifts = newGifts.filter((val, index) => index !== idx);
      setGifts(newGifts);
    }
  }, [gifts]);

  const handleShowEditGift = useCallback((idx: number, isEdit: boolean) => {
    setIsEditMode(isEdit);
    if (isEdit) {
      const rew = gifts[idx];
      const levelReward: LevelRewardInput = {
        amount: rew.amount,
        type: rew.type,
        itemId: rew.itemId,
      };
      setSelectedGift(levelReward);
    } else {
      setSelectedGift(null);
    }
    setGiftIndex(idx);
    setShowEditGift(true);
  }, [gifts]);

  const handleChangeGiftMessage = useCallback((id: string, inEditMode: boolean) => {
    if (id === 'None') {
      setGiftMessage(null);
      setNewMsgTemplateInEdit('');
      return;
    }
    const newMsg = availableMsgTemplates.find((val) => val.id === id);
    if (newMsg) {
      setGiftMessage(newMsg);
      if (inEditMode) {
        setNewMsgTemplateInEdit(newMsg.message);
      }
    }
  }, [availableMsgTemplates]);

  const handleCreateNewMsgTemplate = () => {
    createMsgTemplate({
      variables: {
        msg: newMsgTemplateInEdit,
      }
    }).then((mutRes) => {
      setAvailableMsgTemplates(mutRes.data?.createMessageTemplate ?? []);
    });
  };

  const handleEditNewMsgTemplate = () => {
    if (giftMessage) {
      editMsgTemplate({
        variables: {
          id: giftMessage.id,
          msg: newMsgTemplateInEdit,
        }
      }).then((mutRes) => {
        const newTmps = mutRes.data?.editMessageTemplate ?? [];
        setAvailableMsgTemplates(newTmps);
        const newMessage = newTmps.find((value) => value.id === giftMessage.id);
        if (newMessage) {
          setGiftMessage(newMessage);
        }
      });
    }
  };

  const handleDeleteNewMsgTemplate = useCallback(() => {
    if (giftMessage) {
      deleteMsgTemplate({
        variables: {
          id: giftMessage.id,
        }
      }).then((mutRes) => {
        setAvailableMsgTemplates(mutRes.data?.deleteMessageTemplate ?? []);
        setGiftMessage(null);
        setNewMsgTemplateInEdit('');
      });
    }
  }, [giftMessage, deleteMsgTemplate]);

  const mainLoading = numberOfRecipLoading || sendGiftsLoading;
  const msgTemplateLoading = createMTLoading || editMTLoading || deleteMTLoading;
  return (
    <Card className="shadow mb-4">
      <EditRewardsModal
        isEdit={isEditMode}
        tier={null}
        show={showEditGift}
        level={0}
        reward={selectedGift}
        handleSave={handleAddGift}
        handleClose={() => setShowEditGift(false)}
        disableCurrencySpecialEvent
        disableSubscriptionDay={false}
        disableMonthlyBuff
        enableItemLevelEdit
        enableExtendedFragmentSelector
        liveOpsAvailabilities={[ItemAvailability.EARN]}
      />
      <MassGiftFilters onChange={onChange} onRefresh={onRefreshNumber} />

      <Card className="m-2 border border-secondary">
        <Card.Body>
          {mainLoading ? (
              <LoadingLogo show={mainLoading} />
            ) : (
              <>
                <CostDisplay metadata={metadata} />
                <Row>
                  <Form.Label className="font-weight-bold ml-3">
                    Number of recipients: {numberOfRecipients}
                  </Form.Label>
                </Row>
              </>
            )
          }
        </Card.Body>
      </Card>

      <Card className="m-2 border border-secondary">
        <Card.Body>
          <Row className="ml-1 mb-4 justify-content-start">
              <Button 
                variant='success'
                className="mr-2"
                onClick={() => handleShowEditGift(gifts.length, false)}>
                <i className='fas fa-plus mr-2'/>
                Add Reward
              </Button>
              <Button onClick={onSendGifts} disabled={numberOfRecipients === 0 || gifts.length === 0}>
                <i className="fas fa-gift mr-2" />
                Send
              </Button>
            
          </Row>
          <Row className="mb-3">
            <Col className="d-flex flex-column align-items-center">
              <Form.Label className="font-weight-bold">Rewards</Form.Label>
              {gifts.map((gift, index) => {
                  return (
                    <Row key={index} className="mt-2 align-items-end">
                      <Chip 
                        reward={gift} 
                        canDelete 
                        btnAction={() => handleRemoveGift(index)} 
                        globalAction={() => handleShowEditGift(index, true)}/>
                    </Row>
                  );
                })}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      

      <Card className="m-2 border border-secondary">
        <Card.Body>
          <Form.Group>
            {msgTemplateLoading ? (
              <LoadingLogo show={msgTemplateLoading} />
            ) : (
            <>
            <Form.Row>
              <Form.Label className='ml-1'>
                Current Message:
              </Form.Label>
              <Form.Label>
                {giftMessage ? giftMessage.message : ''}
              </Form.Label>
            </Form.Row>
            
            <Form.Label>
              Choose Message
            </Form.Label><Form.Control
              as="select"
              value={giftMessage?.id}
              className="mb-4"
              onChange={(e) => handleChangeGiftMessage(e.target.value, msgTemplateEditOn)}>
              <option key="None">
                None
              </option>
              {availableMsgTemplates.map((msgTmp) => {
                return (
                  <option key={msgTmp.id} value={msgTmp.id}>
                    {msgTmp.message}
                  </option>
                );
              })}
            </Form.Control>
            
            <Form.Row>
              <Form.Label className="mr-2 ml-1" >
                Enable Editing Message Template
              </Form.Label>
              <Form.Check type="checkbox" checked={msgTemplateEditOn} className="align-self-start" onChange={(e) => {
                if (!msgTemplateEditOn && giftMessage) {
                  setNewMsgTemplateInEdit(giftMessage.message);
                } else if (msgTemplateEditOn) {
                  setNewMsgTemplateInEdit('');
                }
                setMsgTemplateEditOn(val => !val);
              }}/>
            </Form.Row>


            <Form.Label>
              Create New Message Template
            </Form.Label>
            
            <Form.Control
              as="textarea"
              value={newMsgTemplateInEdit}
              onChange={(e) => setNewMsgTemplateInEdit(e.target.value)}
              />
            
            <Row className="ml-1">
              <Button
                onClick={handleCreateNewMsgTemplate}
                className="mt-4 mr-2"
                disabled={newMsgTemplateInEdit.length === 0 || msgTemplateEditOn}>
                <i className="fas fa-plus mr-2" />
                Create
              </Button>

              <Button
                onClick={handleEditNewMsgTemplate}
                className="mt-4 mr-2"
                disabled={newMsgTemplateInEdit.length === 0 || !msgTemplateEditOn}>
                <i className="fas fa-edit mr-2" />
                Edit
              </Button>

              <Button
                onClick={handleDeleteNewMsgTemplate}
                variant="danger"
                className="mt-4"
                disabled={!giftMessage || !msgTemplateEditOn}>
                <i className="fas fa-trash mr-2" />
                Delete
              </Button>
            </Row>
            </>
            )}
          </Form.Group>
        
        </Card.Body>
      </Card>
    </Card>
  );
};

export default MassGift;