import { useMutation } from '@apollo/client';
import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import {
  BadgeConfig,
  BadgeProgressTaskType,
  BadgeType,
  MetaFragmentType,
  ShardType,
} from '../../../../../../__gqltypes__';
import { LoadingLogo } from '../../../../devtools/components/Modal';
import { UPLOAD_BADGE_ASSET } from '../../../components/apolloQueries';
import DragUpload from './DragUpload';
import { OnValidateItem } from './typedef';

type Props = {
  upgrade: any;
  itemData: BadgeConfig;
  setItemData: React.Dispatch<React.SetStateAction<BadgeConfig>>;
  onValidate?: OnValidateItem;
  isFirst: boolean;
};

export default function UpgradeForm({ upgrade, itemData, setItemData, onValidate, isFirst }: Props) {
  const [uploadAsset, { loading }] = useMutation(UPLOAD_BADGE_ASSET);

  const details = JSON.parse(itemData.details);

  let initialMetaType;
  if (itemData.type === BadgeType.PROGRESS) {
    initialMetaType = MetaFragmentType.BRONZE;
  } else {
    initialMetaType = Object.entries(details.levelDetails[upgrade.level].metaCost).find((entry) => !!entry[1])?.[0];
  }
  let initialShardType;
  if (itemData.type === BadgeType.RARE) {
    initialShardType = Object.entries(details.levelDetails[upgrade.level].shards).find((entry) => !!entry[1])?.[0];
  } else {
    initialShardType = ShardType.BRONZE;
  }

  const initialCurrencyType = details.levelDetails[upgrade.level].diamonds ? 'diamonds' : 'coins';

  const costType = ['BRONZE', 'SILVER', 'GOLD'];
  const currencyTypes = ['coins', 'diamonds'];

  const [metaType, setMetaType] = useState(costType.indexOf(initialMetaType ?? 'BRONZE'));
  const [shardType, setShardType] = useState(costType.indexOf(initialShardType ?? 'BRONZE'));
  const [currencyType, setCurrencyType] = useState(initialCurrencyType);

  const handleUpload = (file: Blob) => {
    uploadAsset({
      variables: {
        input: {
          file,
          id: itemData.id,
          level: upgrade.level,
        },
      },
    }).then(({ data }) => {
      details.levelDetails[upgrade.level].asset = data.uploadBadgeAssets.asset;
      setItemData({ ...itemData, details: JSON.stringify(details) });
    });
  };

  // @ts-ignore
  const handleAmountChange = (e) => {
    details.levelDetails[upgrade.level][
      itemData.type === BadgeType.CATEGORY
        ? 'fragmentsCost'
        : itemData.type === BadgeType.PROGRESS
        ? 'value'
        : currencyType
    ] = e.target.value;
    setItemData({ ...itemData, details: JSON.stringify(details) });
  };

  // @ts-ignore
  const handleMetaCostChange = (e) => {
    details.levelDetails[upgrade.level].metaCost[costType[metaType]] = +(e.target.value ?? 0);
    if (metaType === 0) {
      details.levelDetails[upgrade.level].metaCost[costType[1]] = 0;
      details.levelDetails[upgrade.level].metaCost[costType[2]] = 0;
    } else if (metaType === 1) {
      details.levelDetails[upgrade.level].metaCost[costType[2]] = 0;
      details.levelDetails[upgrade.level].metaCost[costType[0]] = 0;
    } else {
      details.levelDetails[upgrade.level].metaCost[costType[1]] = 0;
      details.levelDetails[upgrade.level].metaCost[costType[0]] = 0;
    }
    setItemData({ ...itemData, details: JSON.stringify(details) });
  };

  // @ts-ignore
  const handleMetaTypeChange = (e) => {
    setMetaType(costType.indexOf(e.target.value));
    handleMetaCostChange({ target: { value: 0 } });
  };

  // @ts-ignore
  const handleShardCostChange = (e) => {
    details.levelDetails[upgrade.level].shards[costType[shardType]] = +(e.target.value ?? 0);
    if (shardType === 0) {
      details.levelDetails[upgrade.level].shards[costType[1]] = 0;
      details.levelDetails[upgrade.level].shards[costType[2]] = 0;
    } else if (shardType === 1) {
      details.levelDetails[upgrade.level].shards[costType[2]] = 0;
      details.levelDetails[upgrade.level].shards[costType[0]] = 0;
    } else {
      details.levelDetails[upgrade.level].shards[costType[1]] = 0;
      details.levelDetails[upgrade.level].shards[costType[0]] = 0;
    }
    setItemData({ ...itemData, details: JSON.stringify(details) });
  };

  // @ts-ignore
  const handleShardTypeChange = (e) => {
    setShardType(costType.indexOf(e.target.value));
    handleShardCostChange({ target: { value: 0 } });
  };

  // @ts-ignore
  const handleCurrencyTypeChange = (e) => {
    setCurrencyType(e.target.value);

    details.levelDetails[upgrade.level][e.target.value === 'coins' ? 'diamonds' : 'coins'] = undefined;
    details.levelDetails[upgrade.level][e.target.value] = 0;
    setItemData({ ...itemData, details: JSON.stringify(details) });
  };

  return (
    <Form>
      <hr />
      <h5>
        Item level
        {upgrade.level}
      </h5>
      <LoadingLogo show={loading} />

      <DragUpload onUpload={handleUpload} onValidate={onValidate} />
      <Form.Group>
        <Form.Label>Asset</Form.Label>
        <Form.Control value={upgrade.asset} disabled />
      </Form.Group>

      {(itemData.type === BadgeType.SHOP || (itemData.type === BadgeType.LIVE_EVENT && !isFirst)) && (
        <Form.Group>
          <Form.Label>Currency type: </Form.Label>
          <Form.Control as="select" value={currencyType} onChange={handleCurrencyTypeChange}>
            {currencyTypes.map((type) => (
              <option value={type} key={type}>
                {type}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )}
      {!(isFirst && itemData.type === BadgeType.LIVE_EVENT) && itemData.type !== BadgeType.RARE && (
        <Row>
          <Col>
            <p>
              {itemData.type === BadgeType.CATEGORY
                ? 'Amount of fragments:'
                : itemData.type === BadgeType.SHOP || itemData.type === BadgeType.LIVE_EVENT
                ? 'Amount of currency:'
                : details.progressTaskType === BadgeProgressTaskType.LEVEL
                ? 'Level:'
                : 'Days:'}
            </p>
          </Col>
          <Col md={10}>
            <Form.Group>
              <FormControl
                value={
                  itemData.type === BadgeType.CATEGORY
                    ? upgrade.fragmentsCost
                    : itemData.type === BadgeType.PROGRESS
                    ? upgrade.value
                    : upgrade[currencyType]
                }
                onChange={handleAmountChange}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {itemData.type === BadgeType.RARE && (
        <Row>
          <Col>
            <p>Shards: </p>
          </Col>
          <Col md={10}>
            <Form.Group>
              <Form.Label>Type: </Form.Label>
              <Form.Control as="select" value={costType[shardType]} onChange={handleShardTypeChange}>
                {costType.map((type) => (
                  <option value={type} key={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Amount: </Form.Label>
              <FormControl
                value={details.levelDetails[upgrade.level].shards[costType[shardType]]}
                onChange={handleShardCostChange}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {itemData.type !== BadgeType.PROGRESS && !isFirst && (
        <Row>
          <Col>
            <p>Meta fragments: </p>
          </Col>
          <Col md={10}>
            <Form.Group>
              <Form.Label>Type: </Form.Label>
              <Form.Control as="select" value={costType[metaType]} onChange={handleMetaTypeChange}>
                {costType.map((type) => (
                  <option value={type} key={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Amount: </Form.Label>
              <FormControl
                value={details.levelDetails[upgrade.level].metaCost[costType[metaType]]}
                onChange={handleMetaCostChange}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
    </Form>
  );
}
