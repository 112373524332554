import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import DateTimePicker from 'react-datetime';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import {
  BadgeType,
  CreateUpdateTeamShopItemInput,
  GetBadgesQuery,
  GetCategoriesQuery,
  GetItemsQuery,
  ItemAvailability,
  LevelRewardInputType,
  TeamShopBuyLimitEnum,
  TeamShopItem,
} from '../../../../../../__gqltypes__';
import { GET_BADGES, GET_ITEMS } from '../../../components/apolloQueries';
import { REWARDS_MAP } from '../../../components/formatReward';
import queries from '../../../../curation/utils/queries';

type Props = {
  handleSave: (newReward: CreateUpdateTeamShopItemInput) => void;
  handleClose: () => void;
  isEdit: boolean;
  show: boolean;
  item: TeamShopItem | null;
  disableContentPackReward?: boolean;
  disableMonthlyBuff?: boolean;
  disableCurrencySpecialEvent?: boolean;
  liveOpsAvailabilities?: ItemAvailability[];
};

const extractId = (id: string | null) => {
  if (!id) return null;
  if (id.includes('.')) return id.split('.')[0];
  return id;
};

const EditShopModal = ({
  handleSave,
  handleClose,
  isEdit,
  show,
  item,
  disableContentPackReward,
  disableMonthlyBuff,
  disableCurrencySpecialEvent,
  liveOpsAvailabilities = [ItemAvailability.EARN],
}: Props) => {
  const [selectedType, setSelectedType] = useState<LevelRewardInputType | null>(null);
  const [selectedSubValue, setSelectedSubValue] = useState<string | null>(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [selectedCost, setSelectedCost] = useState(0);
  const [selectedDiscount, setSelectedDiscount] = useState(0);
  const [selectedBuyLimit, setSelectedBuyLimit] = useState<any>(0);
  const [selectedBuyLimitType, setSelectedBuyLimitType] = useState<TeamShopBuyLimitEnum>(
    TeamShopBuyLimitEnum.UNLIMITED
  );
  const [subItems, setSubItems] = useState<{ id: string; name: string }[] | string | null>(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const { data } = useQuery<GetItemsQuery>(GET_ITEMS);
  const { data: badgesData } = useQuery<GetBadgesQuery>(GET_BADGES);
  const { data: categoriesData } = useQuery<GetCategoriesQuery>(queries.GET_CATEGORIES);

  const categories = categoriesData?.app?.contentCategories ?? [];

  useEffect(() => {
    if (isEdit && item) {
      setSelectedType(item.reward.type as LevelRewardInputType);
      setSelectedSubValue('itemId' in item.reward ? extractId(item.reward.itemId ?? null) : null);
      setSelectedAmount(item.reward.amount ?? 0);
      setSelectedCost(item.cost);
      setSelectedBuyLimit(item.buyLimit);
      setSelectedBuyLimitType(item.buyLimitType ?? TeamShopBuyLimitEnum.UNLIMITED);
      setSelectedDiscount(item.discount ?? 0);
      setStartDate(item.startDate);
      setEndDate(item.endDate);
    } else {
      setSelectedAmount(0);
      setSelectedCost(0);
      setSelectedBuyLimit(0);
      setSelectedType(LevelRewardInputType.CURRENCY_COIN);
      setSelectedBuyLimitType(TeamShopBuyLimitEnum.UNLIMITED);
    }
  }, [isEdit, item, show]);

  useEffect(() => {
    if (selectedType !== null) {
      let newSubItems =
        selectedType === LevelRewardInputType.CURRENCY_CATEGORY_FRAGMENT ? categories : REWARDS_MAP[selectedType];

      const stickers =
        data && !_.isEmpty(liveOpsAvailabilities)
          ? data.liveOps.stickers.list.filter((s) => liveOpsAvailabilities.includes(s.liveOpsAvailability))
          : [];
      const profileFrames =
        data && !_.isEmpty(liveOpsAvailabilities)
          ? data.liveOps.profileFrames.list.filter((pf) => liveOpsAvailabilities.includes(pf.liveOpsAvailability))
          : [];
      const appSkins =
        data && !_.isEmpty(liveOpsAvailabilities)
          ? data.liveOps.appskins.list.filter((as) => liveOpsAvailabilities.includes(as.liveOpsAvailability))
          : [];

      const badges = badgesData?.liveOps.badgeConfigs.list.filter((badge) => badge.type === BadgeType.RARE) ?? [];

      if (REWARDS_MAP[selectedType] === 'stickers') {
        newSubItems = stickers;
      } else if (REWARDS_MAP[selectedType] === 'profileFrames') {
        newSubItems = profileFrames;
      } else if (REWARDS_MAP[selectedType] === 'appSkins') {
        newSubItems = appSkins;
      } else if (REWARDS_MAP[selectedType] === 'badges') {
        newSubItems = badges;
      }

      setSubItems(newSubItems);

      if (Array.isArray(newSubItems) && newSubItems.findIndex((value) => value.id === selectedSubValue) < 0) {
        setSelectedSubValue(newSubItems[0]?.id);
      }
    }
  }, [data, selectedType]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? 'Edit' : 'Add'} shop item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  as="select"
                  value={selectedType ?? undefined}
                  onChange={(e) => {
                    setSelectedSubValue(null);
                    setSelectedType(e.currentTarget.value as LevelRewardInputType);
                  }}
                >
                  <Selector
                    disableContentPackReward={disableContentPackReward}
                    disableMonthlyBuff={disableMonthlyBuff}
                    disableCurrencySpecialEvent={disableCurrencySpecialEvent}
                  />
                </Form.Control>
              </Form.Group>
            </Col>
            {Array.isArray(subItems) && (
              <Col>
                <Form.Group>
                  <Form.Control
                    as="select"
                    value={selectedSubValue ?? undefined}
                    onChange={(e) => setSelectedSubValue(e.target.value)}
                  >
                    {subItems.map((type) => (
                      <option value={type.id} key={type.id}>
                        {`${type.name} (${type.id})`}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            )}

            {(!selectedType ||
              !['STICKER', 'PROFILE_FRAME', 'APP_SKIN', 'CONTENT_PACK_REWARD', 'BADGE'].includes(selectedType)) && (
              <Col>
                <Form.Group>
                  <Form.Control
                    placeholder=""
                    value={selectedAmount}
                    onChange={(e) => setSelectedAmount(parseInt(e.target.value, 10))}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <Form.Label className="font-weight-bold">Cost</Form.Label>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  placeholder=""
                  value={selectedCost}
                  onChange={(e) => setSelectedCost(parseInt(e.target.value, 10))}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Label className="font-weight-bold">Buy Limit Type</Form.Label>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  name="tag"
                  as="select"
                  value={selectedBuyLimitType}
                  onChange={(e) => setSelectedBuyLimitType(e.target.value as any)}
                >
                  {Object.entries(TeamShopBuyLimitEnum).map(([key, value], index) => {
                    return (
                      <option key={`${key}-${index}`} value={value}>
                        {key}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className="font-weight-bold">Buy Limit</Form.Label>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  placeholder=""
                  value={selectedBuyLimit}
                  onChange={(e) => setSelectedBuyLimit(parseInt(e.target.value, 10))}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>Discount</Form.Label>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  placeholder=""
                  value={selectedDiscount}
                  onChange={(e) => setSelectedDiscount(parseInt(e.target.value, 10))}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>Start date</Col>
            <Col>
              <DateTimePicker
                dateFormat="YYYY-MM-DD"
                timeFormat={true}
                timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
                // @ts-ignore
                onChange={setStartDate}
                // @ts-ignore
                value={startDate ?? undefined}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>End date</Col>
            <Col>
              <DateTimePicker
                dateFormat="YYYY-MM-DD"
                timeFormat={true}
                timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
                // @ts-ignore
                onChange={setEndDate}
                // @ts-ignore
                value={endDate ?? undefined}
              />
            </Col>
          </Row>
          {selectedType === 'CONTENT_PACK_REWARD' && (
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>ContentPack id</Form.Label>
                  {console.log('selectedSubValue', selectedSubValue)}
                  <Form.Control
                    type="text"
                    onChange={(event) => setSelectedSubValue(event.target.value)}
                    value={selectedSubValue ?? undefined}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() =>
            !!selectedType &&
            handleSave({
              startDate,
              endDate,
              discount: selectedDiscount,
              reward: {
                itemId: selectedSubValue,
                type: selectedType,
                amount: selectedAmount,
              },
              cost: selectedCost,
              buyLimit: selectedBuyLimit,
              buyLimitType: selectedBuyLimitType,
            })
          }
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Selector = ({
  disableContentPackReward,
  disableMonthlyBuff,
  disableCurrencySpecialEvent,
}: {
  disableContentPackReward?: boolean;
  disableMonthlyBuff?: boolean;
  disableCurrencySpecialEvent?: boolean;
}) => {
  const [rewardsMap, setRewardsMap] = useState<string[] | null>(null);

  useEffect(() => {
    const _rewardsMap = Object.keys(REWARDS_MAP);
    if (disableContentPackReward) {
      // disable CONTENT_PACK_REWARD
      const indexContentPackReward = _rewardsMap.indexOf('CONTENT_PACK_REWARD');
      _rewardsMap.splice(indexContentPackReward, 1);
    }
    if (disableMonthlyBuff) {
      // disable MONTHLY_BUFF
      const indexMonthlyBuff = _rewardsMap.indexOf('MONTHLY_BUFF');
      _rewardsMap.splice(indexMonthlyBuff, 1);
    }
    if (disableCurrencySpecialEvent) {
      // disable CURRENCY_SPECIAL_EVENT
      const indexMonthlyBuff = _rewardsMap.indexOf('CURRENCY_SPECIAL_EVENT');
      _rewardsMap.splice(indexMonthlyBuff, 1);
    }
    setRewardsMap(_rewardsMap);
  }, [disableContentPackReward, disableMonthlyBuff]);

  // TODO, use lodash
  const toTitleCase = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

  return (
    <>
      {rewardsMap &&
        rewardsMap.map((type) => (
          <option value={type} key={type}>
            {toTitleCase(type)}
          </option>
        ))}
    </>
  );
};

export default EditShopModal;
