import { gql } from '@apollo/client';

/**
 * FRAGMENT
 */
const TEAM_SHOP_ITEM_FRAGMENT = gql`
  fragment TeamShopItemFragment on TeamShopItem {
    id
    startDate
    endDate
    buyLimit
    buyLimitType
    cost
    discount
    reward {
      type
      amount
      itemId
    }
  }
`;

/**
 * QUERIES
 */
export const GET_TEAM_SHOP = gql`
  query teamShop($limit: Int, $offset: Int, $filter: TeamShopFilter) {
    teamShop(limit: $limit, offset: $offset, filter: $filter) {
      totalCount
      hasMore
      list {
        ...TeamShopItemFragment
      }
    }
  }
  ${TEAM_SHOP_ITEM_FRAGMENT}
`;

/**
 * MUTATION
 */
export const UPDATE_TEAM_SHOP_ITEM = gql`
  mutation updateTeamShopItem($itemId: ID!, $item: CreateUpdateTeamShopItemInput!) {
    updateTeamShopItem(itemId: $itemId, item: $item) {
      totalCount
      hasMore
      list {
        ...TeamShopItemFragment
      }
    }
  }
  ${TEAM_SHOP_ITEM_FRAGMENT}
`;

export const ADD_TEAM_SHOP_ITEM = gql`
  mutation createTeamShopItem($item: CreateUpdateTeamShopItemInput!) {
    createTeamShopItem(item: $item) {
      totalCount
      hasMore
      list {
        ...TeamShopItemFragment
      }
    }
  }
  ${TEAM_SHOP_ITEM_FRAGMENT}
`;

export const DELETE_TEAM_SHOP_ITEM = gql`
  mutation deleteTeamShopItem($itemId: ID!) {
    deleteTeamShopItem(itemId: $itemId) {
      totalCount
      hasMore
      list {
        ...TeamShopItemFragment
      }
    }
  }
  ${TEAM_SHOP_ITEM_FRAGMENT}
`;
