import { LiveEventShopItemConfig, LevelRewardInput, LevelRewardInputType, LiveEventShopItemInput } from "../../../../../__gqltypes__";

export function convertLiveEventShopItemToLevelRewardInput(reward: LiveEventShopItemConfig): LevelRewardInput {
  return  {
      type: Object.values(LevelRewardInputType)
      .find((val) => val === reward.type.toString()) ?? LevelRewardInputType.CURRENCY_COIN,
      amount: reward.amount,
      itemId: reward.itemId,
  };
}

export function convertLiveEventShopItemToInputs(
  rewards: Array<LiveEventShopItemConfig>
): Array<LiveEventShopItemInput> {
  return rewards.map((reward) => {
    return { item: convertLiveEventShopItemToLevelRewardInput(reward), cost: reward.cost, buyLimit: reward.buyLimit };
  });
}