import { gql } from '@apollo/client';

/**
 * FRAGMENTS
 */

export const SALE_CONFIG_FRAGMENT = gql`
  fragment SaleConfigFragment on SaleConfig {
    __typename
    id
    startDate
    stopDate
    name
    targetedProductId
    saleProductId
    newPrice
    oldPrice
    offerPercentage
    tag
    ruleMaximumNumberOfClaim
    purchaseLimitType
  }
`;

export const DAILY_DEALS_FRAGMENT = gql`
  fragment HighPriorDailyDealsFragment on Item {
    __typename
    id
  }
`;

export const BUNDLE_ITEMS_FRAGMENT = gql`
  fragment BundleItemFragment on BundleItem {
    __typename
    itemId
    type
    amount
  }
`;

export const SALE_BUNDLE_CONFIG_FRAGMENT = gql`
  fragment SaleBundleConfigFragment on SaleBundleConfig {
    __typename
    id
    startDate
    stopDate
    name
    backgroundImage
    offerPercentage
    tag
    ruleMaximumNumberOfClaim
    purchaseLimitType
    displayTimer
    skuId
    items {
      ...BundleItemFragment
    }
  }
  ${BUNDLE_ITEMS_FRAGMENT}
`;

export const SALE_EVENT_CONFIG_FRAGMENT = gql`
  fragment SaleEventConfigFragment on SaleEventConfig {
    __typename
    id
    startDate
    stopDate
    priority
    status
    name
    sales {
      ...SaleConfigFragment
    }
    packs {
      ...SaleBundleConfigFragment
    }
    dailyItems {
      ...HighPriorDailyDealsFragment
    }
    subscriptions {
      id
      discount
      backgroundTheme
    }
    isHardCoded
    filterUserIds
    filterSubscriptionTier
    filterUserMinCreationDate
    filterUserMaxCreationDate
    filterAppVersion
    filterUserLevel
    filterHadSub
    assetsConfig {
      backgroundImage
      characterImage
    }
  }
  ${SALE_CONFIG_FRAGMENT}
  ${SALE_BUNDLE_CONFIG_FRAGMENT}
  ${DAILY_DEALS_FRAGMENT}
`;

/**
 * QUERIES
 */
export const APP_SHOP_DIAMONDS_SALES = gql`
  query getAppShopDiamondsSales {
    app {
      shopDiamondsSales {
        id
        amount
        sku
        asset
      }
    }
  }
`;
export const APP_SHOP_BUNDLES_SALES = gql`
  query getAppShopBundlesSales {
    liveOps {
      bundleSales {
        id
        cost
        sku
        name
      }
    }
  }
`;
export const APP_BUNDLES_NAMES_AND_TAGS = gql`
  query getAppBundlesNamesAndTags {
    liveOps {
      bundleNames
      bundleTags
    }
  }
`;
export const LIVEOPS_SUB_SALES_OFFERS = gql`
  query getSubscriptionSaleOffers {
    liveOps {
      initialSubscriptionOffers {
        id
        discount
        backgroundTheme
      }
    }
  }
`;
export const APP_COINS_SALES = gql`
  query getAppCoinsSales {
    app {
      coinsSales {
        id
        amount
        asset
        cost {
          id
          amount
        }
      }
    }
  }
`;
export const APP_BOOSTS_SALES = gql`
  query getAppBoostsSales {
    app {
      boostsSales {
        id
        amount
        asset
        boostType
        cost {
          id
          amount
        }
      }
    }
  }
`;
export const LIVEOPS_SALE_EVENT_CONFIG_QUERY = gql`
  query getLiveOpsSaleEventConfig($saleEventId: ID!) {
    liveOps {
      saleEventConfig(saleEventId: $saleEventId) {
        ...SaleEventConfigFragment
      }
    }
  }
  ${SALE_EVENT_CONFIG_FRAGMENT}
`;
export const LIVEOPS_SALE_EVENT_CONFIGS_QUERY = gql`
  query getLiveOpsSaleEventConfigs {
    liveOps {
      saleEventConfigs {
        ...SaleEventConfigFragment
      }
    }
  }
  ${SALE_EVENT_CONFIG_FRAGMENT}
`;

export const CREATE_SALE_EVENT = gql`
  mutation createSaleEvent($input: CreateSaleEventInput!) {
    createSaleEvent(input: $input) {
      saleEvent {
        ...SaleEventConfigFragment
      }
    }
  }
  ${SALE_EVENT_CONFIG_FRAGMENT}
`;

export const UPDATE_SALE_EVENT = gql`
  mutation updateSaleEvent($input: UpdateSaleEventInput!) {
    updateSaleEvent(input: $input) {
      saleEvent {
        ...SaleEventConfigFragment
      }
    }
  }
  ${SALE_EVENT_CONFIG_FRAGMENT}
`;

export const DELETE_SALE_EVENT = gql`
  mutation deleteSaleEvent($input: DeleteSaleEventInput!) {
    deleteSaleEvent(input: $input) {
      saleEventIdDeleted
    }
  }
`;

export const UPLOAD_SALE_EVENT_ASSET = gql`
  mutation uploadSaleEventAsset($input: UploadSaleEventAssetInput!) {
    uploadSaleEventAsset(input: $input) {
      saleEvent {
        ...SaleEventConfigFragment
      }
    }
  }
  ${SALE_EVENT_CONFIG_FRAGMENT}
`;
