import React, { useEffect, useState } from 'react';
import { Button, Form, FormControlProps } from 'react-bootstrap';

import { SubscriptionSaleConfig } from '../../../../../../__gqltypes__';

type Props = {
  availableOffers: {id: string; discount: number}[],
  subSale: SubscriptionSaleConfig;
  myIndex: number;
  handleSubUpdate: (updatedSub: SubscriptionSaleConfig, index: number) => SubscriptionSaleConfig;
  handleSubDelete: (index: number) => void;
}

type EventType = React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;

const availableBackThemes = ['BASIC', 'BLACK'];

export function SubscriptionSaleRow({availableOffers, subSale, myIndex, handleSubUpdate, handleSubDelete}: Props) {
  const [sub, setSub] = useState<SubscriptionSaleConfig>(subSale);

  const handleChangeOfferId = (event: EventType) => {
    const updatedSub = {...sub};
    updatedSub.id = event.currentTarget.value;
    updatedSub.discount = availableOffers.find((val) => val.id === updatedSub.id)?.discount ?? 0;
    setSub(updatedSub);
    handleSubUpdate(updatedSub, myIndex);
  };

  const handleChangeBackTheme = (event: EventType) => {
    const updatedSub = {...sub};
    updatedSub.backgroundTheme = event.currentTarget.value;
    setSub(updatedSub);
    handleSubUpdate(updatedSub, myIndex);
  };

  useEffect(() => {
    if (sub.id === 'empty') {
      const updatedSub = {...sub};
      updatedSub.id = availableOffers[0].id;
      updatedSub.discount = availableOffers[0].discount;
      updatedSub.backgroundTheme = availableBackThemes[0];
      setSub(updatedSub);
      handleSubUpdate(updatedSub, myIndex);
    }
  }, [handleSubUpdate, availableOffers, sub, myIndex]);

  return (
    <tr>
      <td style={{verticalAlign: 'middle'}}>
        <Form.Control
          name="tag"
          as="select"
          value={sub.id}
          onChange={handleChangeOfferId}>
          {availableOffers.map((val, index) => {
            return (
              <option key={index} value={val.id}>
                {val.id}
              </option>
            );
          })}
        </Form.Control>
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <Form.Label>{sub.discount}</Form.Label>
      </td>
      <td style={{verticalAlign: 'middle'}}>
        <Form.Control
          name="tag"
          as="select"
          value={sub.backgroundTheme ?? availableBackThemes[0]}
          onChange={handleChangeBackTheme}>
          {availableBackThemes.map((val, index) => {
            return (
              <option key={index} value={val}>
                {val}
              </option>
            );
          })}
        </Form.Control>
      </td>
      <td>
        <Button
          onClick={() => {
            handleSubDelete(myIndex);
          }}
          variant="danger">
          <i className="fas fa-trash mr-2" />
        </Button>
      </td>
    </tr>
  );
}