import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import {
  AppInformationQuery,
  BadgeType,
  GetBadgesQuery,
  GetItemsQuery,
  ItemAvailability,
  LevelRewardInput,
  LevelRewardInputType,
} from '../../../../__gqltypes__';
import { GET_BADGES, GET_ITEMS } from './apolloQueries';
import { REWARDS_MAP } from './formatReward';
import { tierName } from './monthDataReducer';
import { APP_INFORMATION } from '../../devtools/graphql';

type Props = {
  tier: tierName | null;
  level: number | null;
  handleSave: (newReward: LevelRewardInput) => void;
  handleClose: () => void;
  isEdit: boolean;
  show: boolean;
  reward: ({ id?: string } & LevelRewardInput) | null;
  disableContentPackReward?: boolean;
  disableMonthlyBuff?: boolean;
  disableCurrencySpecialEvent?: boolean;
  disableSubscriptionDay?: boolean;
  enableItemLevelEdit?: boolean;
  enableExtendedFragmentSelector?: boolean;
  liveOpsAvailabilities?: ItemAvailability[];
};

const extractId = (id: string | null) => {
  if (!id) return null;
  if (id.includes('.')) return id.split('.')[0];
  return id;
};

const EditRewardsModal = ({
  tier,
  level,
  handleSave,
  handleClose,
  isEdit,
  show,
  reward,
  disableContentPackReward,
  disableMonthlyBuff,
  disableCurrencySpecialEvent,
  disableSubscriptionDay = true,
  enableItemLevelEdit,
  enableExtendedFragmentSelector,
  liveOpsAvailabilities = [ItemAvailability.EARN],
}: Props) => {
  const [selectedType, setSelectedType] = useState<LevelRewardInputType | null>(null);
  const [selectedSubValue, setSelectedSubValue] = useState<string | null>(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [subItems, setSubItems] = useState<{ id: string; name: string }[] | string | null>(null);
  const [itemLevel, setItemLevel] = useState(0);

  const getItemLevelSufix = (itemLevel_: number) => {
    return !Number.isNaN(itemLevel_) && itemLevel_ > 0 ? `.${itemLevel_}` : '';
  };

  const getItemLevel = (itemId: string) => {
    if (!itemId || itemId.length < 38) {
      return 0;
    }
    return parseInt(itemId[37], 10);
  };

  const { data } = useQuery<GetItemsQuery>(GET_ITEMS);
  const { data: badgesData } = useQuery<GetBadgesQuery>(GET_BADGES);
  const {data: appData, loading: appDataLoading} = useQuery<AppInformationQuery>(APP_INFORMATION, {
    skip: !enableExtendedFragmentSelector
  });

  const extendedFragmentCategories = useMemo(() => {
    if (enableExtendedFragmentSelector && !appDataLoading && appData) {
      return appData.app.contentCategories.map((val) => ({
        id: val.id,
        name: val.name,
      }));
    }
    return [] as {id: string; name: string;}[];
  }, [appData, appDataLoading, enableExtendedFragmentSelector]);

  useEffect(() => {
    if (isEdit && reward) {
      setSelectedType(reward.type);
      setSelectedSubValue('itemId' in reward ? extractId(reward.itemId ?? null) : null);
      setSelectedAmount(reward.amount);
      setItemLevel('itemId' in reward ? getItemLevel(reward.itemId ?? '') : 0);
    } else {
      setSelectedAmount(0);
      setSelectedType(LevelRewardInputType.CURRENCY_COIN);
    }
  }, [isEdit, reward, show]);

  useEffect(() => {
    if (selectedType !== null) {
      let newSubItems = REWARDS_MAP[selectedType];

      const stickers =
        data && !_.isEmpty(liveOpsAvailabilities)
          ? data.liveOps.stickers.list.filter((s) => liveOpsAvailabilities.includes(s.liveOpsAvailability))
          : [];
      const profileFrames =
        data && !_.isEmpty(liveOpsAvailabilities)
          ? data.liveOps.profileFrames.list.filter((pf) => liveOpsAvailabilities.includes(pf.liveOpsAvailability))
          : [];
      const appSkins =
        data && !_.isEmpty(liveOpsAvailabilities)
          ? data.liveOps.appskins.list.filter((as) => liveOpsAvailabilities.includes(as.liveOpsAvailability))
          : [];
      const badges = badgesData?.liveOps.badgeConfigs.list.filter((badge) => badge.type === BadgeType.SHOP) ?? [];

      const markEarnEachLevel = (item: { id: string; name: string; liveOpsAvailability: ItemAvailability }) => {
        const isEarnEachLevel = item.liveOpsAvailability === ItemAvailability.EARN_EACH_LEVEL;
        return {
          ...item,
          name: isEarnEachLevel ? `* ${item.name}` : item.name,
        };
      };

      if (REWARDS_MAP[selectedType] === 'stickers') {
        newSubItems = stickers.map(markEarnEachLevel);
      } else if (REWARDS_MAP[selectedType] === 'profileFrames') {
        newSubItems = profileFrames.map(markEarnEachLevel);
      } else if (REWARDS_MAP[selectedType] === 'appSkins') {
        newSubItems = appSkins.map(markEarnEachLevel);
      } else if (REWARDS_MAP[selectedType] === 'badges') {
        newSubItems = badges;
      } else if (selectedType === LevelRewardInputType.CURRENCY_CATEGORY_FRAGMENT) {
        if (enableExtendedFragmentSelector) {
          newSubItems = newSubItems
          ? (newSubItems as typeof extendedFragmentCategories).concat(extendedFragmentCategories)
          :extendedFragmentCategories;
        }
      }

      setSubItems(newSubItems);

      if (
        Array.isArray(newSubItems) &&
        newSubItems.findIndex((value) => {
          return value.id === extractId(selectedSubValue);
        }) < 0
      ) {
        setSelectedSubValue(newSubItems[0]?.id);
      }
    }
  }, [data, selectedType]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {isEdit ? 'Edit' : 'Add'} a reward for Level
          {(level ?? 0) + 1} {tier} tier
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  as="select"
                  value={selectedType ?? undefined}
                  onChange={(e) => {
                    setSelectedSubValue(null);
                    setSelectedType(e.currentTarget.value as LevelRewardInputType);
                  }}
                >
                  <Selector
                    disableContentPackReward={disableContentPackReward}
                    disableMonthlyBuff={disableMonthlyBuff}
                    disableCurrencySpecialEvent={disableCurrencySpecialEvent}
                    disableSubscriptionDay={disableSubscriptionDay}
                  />
                </Form.Control>
              </Form.Group>
            </Col>
            {Array.isArray(subItems) && (
              <Col>
                <Form.Group>
                  <Form.Control
                    as="select"
                    value={selectedType !== LevelRewardInputType.CURRENCY_CATEGORY_FRAGMENT
                      ? (extractId(selectedSubValue) ?? undefined)
                      : selectedSubValue ?? undefined}
                    onChange={(e) => setSelectedSubValue(e.target.value)}
                  >
                    {subItems.map((type) => (
                      <option value={type.id} key={type.id}>
                        {`${type.name} (${type.id})`}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            )}

            {(!selectedType ||
              !['STICKER', 'PROFILE_FRAME', 'APP_SKIN', 'CONTENT_PACK_REWARD', 'BADGE'].includes(selectedType)) && (
              <Col>
                <Form.Group>
                  <Form.Control
                    placeholder=""
                    value={selectedAmount}
                    onChange={(e) => setSelectedAmount(parseInt(e.target.value, 10))}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>
          {enableItemLevelEdit && selectedType && ['PROFILE_FRAME', 'APP_SKIN'].includes(selectedType) && (
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label className="font-weight-bold">Item Level [allowed from 0 to 2]</Form.Label>
                  <Form.Control
                    placeholder=""
                    value={itemLevel}
                    onChange={(e) => {
                      let newItemLevel = parseInt(e.target.value, 10);
                      newItemLevel = Math.min(2, Math.max(0, newItemLevel));
                      setItemLevel(newItemLevel);
                      if (selectedSubValue) {
                        setSelectedSubValue(selectedSubValue.slice(0, 36).concat(getItemLevelSufix(newItemLevel)));
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
          {selectedType === 'CONTENT_PACK_REWARD' && (
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>ContentPack id</Form.Label>
                  {console.log('selectedSubValue', selectedSubValue)}
                  <Form.Control
                    type="text"
                    onChange={(event) => setSelectedSubValue(event.target.value)}
                    value={selectedSubValue ?? undefined}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() =>
            !!selectedType &&
            handleSave({
              itemId: selectedSubValue,
              type: selectedType,
              amount: selectedAmount,
            })
          }
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Selector = ({
  disableContentPackReward,
  disableMonthlyBuff,
  disableCurrencySpecialEvent,
  disableSubscriptionDay,
}: {
  disableContentPackReward?: boolean;
  disableMonthlyBuff?: boolean;
  disableCurrencySpecialEvent?: boolean;
  disableSubscriptionDay?: boolean;
}) => {
  const [rewardsMap, setRewardsMap] = useState<string[] | null>(null);

  useEffect(() => {
    const _rewardsMap = Object.keys(REWARDS_MAP);
    if (disableContentPackReward) {
      // disable CONTENT_PACK_REWARD
      const indexContentPackReward = _rewardsMap.indexOf('CONTENT_PACK_REWARD');
      _rewardsMap.splice(indexContentPackReward, 1);
    }
    if (disableMonthlyBuff) {
      // disable MONTHLY_BUFF
      const indexMonthlyBuff = _rewardsMap.indexOf('MONTHLY_BUFF');
      _rewardsMap.splice(indexMonthlyBuff, 1);
    }
    if (disableCurrencySpecialEvent) {
      // disable CURRENCY_SPECIAL_EVENT
      const indexMonthlyBuff = _rewardsMap.indexOf('CURRENCY_SPECIAL_EVENT');
      _rewardsMap.splice(indexMonthlyBuff, 1);
    }

    if (disableSubscriptionDay) {
      // disable SUBSCRIPTION_DAY
      const indexMonthlyBuff = _rewardsMap.indexOf('SUBSCRIPTION_DAY');
      _rewardsMap.splice(indexMonthlyBuff, 1);
    }
    setRewardsMap(_rewardsMap);
  }, [disableContentPackReward, disableCurrencySpecialEvent, disableMonthlyBuff, disableSubscriptionDay]);

  // TODO, use lodash
  const toTitleCase = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

  return (
    <>
      {rewardsMap &&
        rewardsMap.map((type) => (
          <option value={type} key={type}>
            {toTitleCase(type)}
          </option>
        ))}
    </>
  );
};

export default EditRewardsModal;
