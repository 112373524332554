import { gql } from '@apollo/client';

/**
 * FRAGMENT
 */
const LIVE_EVENT_FRAGMENT = gql`
  fragment LiveEventFragment on LiveEvent {
    id
    name
    startDate
    endDate
    isDraft
    contentPacks {
      id
      name
      popularity
      explicitContentStatus
    }
    filterUserIds
    filterOnlyAdmin
    filterVersion
    shop {
      type
      amount
      itemId
      cost
      buyLimit
    }
  }
`;

/**
 * QUERIES
 */
export const GET_LIVE_EVENTS = gql`
  query liveEvents($pagination: PaginationParams) {
    liveOps {
      liveEvents(pagination: $pagination) {
        totalCount
        hasMore
        list {
          id
          startDate
          name
        }
      }
    }
  }
`;

export const GET_LIVE_EVENT = gql`
  query liveEvent($liveEventId: ID!) {
    liveEvent(liveEventId: $liveEventId) {
      ...LiveEventFragment
    }
  }
  ${LIVE_EVENT_FRAGMENT}
`;

/**
 * MUTATION
 */
export const UPDATE_LIVE_EVENT = gql`
  mutation updateLiveEvent($input: UpdateLiveEventInput!) {
    updateLiveEvent(input: $input) {
      liveEvent {
        ...LiveEventFragment
      }
    }
  }
  ${LIVE_EVENT_FRAGMENT}
`;

export const UPDATE_LIVE_EVENT_CONTENT_PACKS = gql`
  mutation updateLiveEventContentPacks($input: UpdateLiveEventContentPacksInput!) {
    updateLiveEventContentPacks(input: $input) {
      liveEvent {
        ...LiveEventFragment
      }
    }
  }
  ${LIVE_EVENT_FRAGMENT}
`;

export const CREATE_LIVE_EVENT = gql`
  mutation createLiveEvent($input: CreateLiveEventInput!) {
    createLiveEvent(input: $input) {
      liveEvent {
        ...LiveEventFragment
      }
    }
  }
  ${LIVE_EVENT_FRAGMENT}
`;

export const REMOVE_LIVE_EVENT = gql`
  mutation removeLiveEvent($input: RemoveLiveEventInput!) {
    removeLiveEvent(input: $input) {
      isRemoved
    }
  }
`;

export const ADD_LIVE_EVENT_CONTENT_PACK = gql`
  mutation addSpecialEventContentPack($input: AddSpecialEventContentPackInput!) {
    addSpecialEventContentPack(input: $input) {
      specialEvent {
        id
        name
      }
      contentPack {
        id
        name
      }
    }
  }
`;

export const REMOVE_LIVE_EVENT_CONTENT_PACK = gql`
  mutation removeSpecialEventContentPack($input: RemoveSpecialEventContentPackInput!) {
    removeSpecialEventContentPack(input: $input) {
      specialEvent {
        id
        name
      }
      contentPack {
        id
        name
      }
    }
  }
`;

export const UPDATE_LIVE_EVENT_SHOP = gql`
  mutation updateLiveEventShop($input: UpdateLiveEventShopInput!) {
    updateLiveEventShop(input: $input) {
      liveEvent {
        ...LiveEventFragment
      }
    }
  }
  ${LIVE_EVENT_FRAGMENT}
`;
