import { gql } from '@apollo/client';

export const GET_NUMBER_OF_RECIPIENTS = gql`
  query getNumberOfRecipients($filters: GiftFilters!) {
    getPotentialNumberOfRecipients(filters: $filters) {
      count
      metadataList
    }
  }
`;

export const SEND_MASS_GIFT = gql`
  mutation sendMassGift($filters: GiftFilters!, $gifts: [LevelRewardInput!]!, $messageId: String) {
    sendGiftAdmin(filters: $filters, gifts: $gifts, messageId: $messageId) {
      count
      metadataList
    }
  }
`;

export const GET_MESSAGE_TEMPLATES = gql`
  query getAllMessageTemplates {
    getMessageTemplates {
      id
      message
    }
  }
`;

export const CREATE_MESSAGE_TEMPLATE = gql`
  mutation createMessageTemplate($msg: String!) {
    createMessageTemplate(msg: $msg) {
      id
      message
    }
  }
`;

export const EDIT_MESSAGE_TEMPLATE = gql`
  mutation editMessageTemplate($id: ID!, $msg: String!) {
    editMessageTemplate(id: $id, msg: $msg) {
      id
      message
    }
  }
`;

export const DELETE_MESSAGE_TEMPLATE = gql`
  mutation deleteMessageTemplate($id: ID!) {
    deleteMessageTemplate(id: $id) {
      id
      message
    }
  }
`;