// A modal displayed to choose settings of the next Tournament

import moment from 'moment';
import React from 'react';
import { Button, Col, Modal, ModalProps, Row } from 'react-bootstrap';
import DateTimePicker from 'react-datetime';
import { applyChangeIfDateValid, validIfSameDayOrAfter } from '../../../../../../utils/datepicker';

type Props = {
  loading: boolean;
  onAdd: ({ startDate, endDate }: { startDate: Date; endDate: Date }) => void;
} & ModalProps;

const AddLiveEventModal = ({ show, onHide, onAdd, loading }: Props) => {
  /**
   * GRAPHQL
   */
  /**
   * STATE
   */
  /**
   * @type {[import('moment').Moment, Function]} Loading
   */
  const [startDate, setStartDate] = React.useState<Date>(moment().utc().set({ hour: 0, minute: 0, second: 0 }).toDate());
  const [endDate, setEndDate] = React.useState<Date>(
    moment().utc().set({ hour: 0, minute: 0, second: 0 }).add(2, 'days').toDate()
  );
  /**
   * STATE UPDATE LOGIC
   */

  // HELPER METHODS
  const sanitizeDate = () => {
    setStartDate((rawDate: Date) => moment(rawDate).startOf('minute').toDate());
    setEndDate((rawDate: Date) => moment(rawDate).startOf('minute').toDate());
  };

  const handleSubmit = () => {
    sanitizeDate();
    const yesterday = moment().subtract(1, 'days');
    onAdd({
      startDate,
      endDate,
    });
  };

  /**
   * VIEW
   */
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <Row>
          <Col xs={4}>Start date UTC</Col>
          <Col xs={6}>
            <DateTimePicker
              dateFormat="YYYY-MM-DD"
              utc={true}
              timeFormat={true}
              timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
              onChange={applyChangeIfDateValid(setStartDate)}
              initialValue={startDate}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>End date UTC</Col>
          <Col xs={6}>
            <DateTimePicker
              dateFormat="YYYY-MM-DD"
              utc={true}
              timeFormat={true}
              timeConstraints={{ minutes: { min: 0, max: 0, step: 0 } }}
              onChange={applyChangeIfDateValid(setEndDate)}
              initialValue={endDate}
            />
          </Col>
        </Row>
        <Row className="mt-3 pl-1">
          <Button disabled={loading} onClick={handleSubmit}>
            Add
          </Button>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AddLiveEventModal;
