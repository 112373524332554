import React from 'react';
import BaseSelectorSlicer from '../components/BaseSelectorSlicer';
import { slicerPropTypes } from '../slicerPropTypes';

import countries from '../../../models/slicers/countries.json';

const options = countries.map(({ name, code }) => ({
  text: name,
  value: code,
}));

const DefaultCountrySlicer = ({ id, value, onChange, title }) => (
  <BaseSelectorSlicer id={id} value={value} onChange={onChange} options={options} disableAll title={title} />
);

DefaultCountrySlicer.propTypes = slicerPropTypes;

DefaultCountrySlicer.defaultProps = {
  id: 'Countries',
  onChange: () => {},
};

export default DefaultCountrySlicer;
