import React from 'react';

import BaseSelectorSlicer from '../components/BaseSelectorSlicer';
import defaultIsSpender from '../../../models/slicers/defaultIsSpender.json';
import { slicerPropTypes } from '../slicerPropTypes';

const options = Object.keys(defaultIsSpender.queries).map((key) => ({
  text: key,
  value: key,
}));

const DefaultIsSpenderSlicer = ({ id, value, onChange, title }) => (
  <BaseSelectorSlicer id={id} value={value} onChange={onChange} options={options} title={title} />
);

DefaultIsSpenderSlicer.propTypes = slicerPropTypes;

DefaultIsSpenderSlicer.defaultProps = {
  id: 'Paying/Non paying User',
  onChange: () => {},
};

export default DefaultIsSpenderSlicer;
