import React from 'react';

import BaseSelectorSlicer from '../components/BaseSelectorSlicer';
import { slicerPropTypes } from '../slicerPropTypes';

import defaultPlatform from '../../../models/slicers/defaultPlatform.json';

const options = Object.keys(defaultPlatform.queries).map((key) => ({
  text: key,
  value: key,
}));

const DefaultPlatformSlicer = ({ id, value, onChange, title }) => (
  <BaseSelectorSlicer id={id} value={value} onChange={onChange} options={options} title={title} />
);

DefaultPlatformSlicer.propTypes = slicerPropTypes;

DefaultPlatformSlicer.defaultProps = {
  id: 'Platform',
  onChange: () => {},
};

export default DefaultPlatformSlicer;
