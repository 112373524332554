import React, { useMemo } from 'react';
import Card from 'react-bootstrap/Card';
import EditableParam from './EditableParam';

type Props<M> = {
  meta: M;
  onChange: (meta: M) => void;
};

export default function MetaFragmentsEditor({
  meta,
  onChange,
}: Props<{ BRONZE: string; SILVER: string; GOLD: string }>) {
  const { BRONZE, SILVER, GOLD } = useMemo(
    () => ({
      BRONZE: meta?.BRONZE,
      SILVER: meta?.SILVER,
      GOLD: meta?.GOLD,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // @ts-ignore
  const handleChangeAmount = (key: string) => (event) => {
    const { value } = event.target;
    if (onChange) {
      onChange({ ...meta, [key]: value });
    }
  };

  return (
    <Card>
      <Card.Body>
        <EditableParam
          key="BRONZE"
          title="BRONZE"
          value={meta?.BRONZE}
          onChange={handleChangeAmount('BRONZE')}
          placeholder={BRONZE}
        />
        <EditableParam
          key="SILVER"
          title="SILVER"
          value={meta?.SILVER}
          onChange={handleChangeAmount('SILVER')}
          placeholder={SILVER}
        />
        <EditableParam
          key="GOLD"
          title="GOLD"
          value={meta?.GOLD}
          onChange={handleChangeAmount('GOLD')}
          placeholder={GOLD}
        />
      </Card.Body>
    </Card>
  );
}
