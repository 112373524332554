import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Accordion, Button, Card, Col, Form, Row } from "react-bootstrap";
import DateTimePicker from 'react-datetime';
import { 
  CurrencyBoostType, 
  GetTeamHeatInfoQuery, 
  GetTeamHeatInfoQueryVariables, 
  ResetClaimedTeamHeatMilestonesMutation, 
  ResetClaimedTeamHeatMilestonesMutationVariables, 
  TeamHeatBoost,
  UpdateTeamBoostsMutation,
  UpdateTeamBoostsMutationVariables,
  UpdateTeamHeatInfoMutation,
  UpdateTeamHeatInfoMutationVariables,
  UserSearchType
} from "../../../../../../__gqltypes__";
import { GET_TEAM_HEAT_INFO, RESET_CLAIMED_TEAM_HEAT_MILESTONES, UPDATE_TEAM_BOOSTS, UPDATE_TEAM_HEAT_INFO } from "../../../graphql";
import { LoadingLogo } from "../../../components/Modal";
import { applyChangeIfDateValid } from "../../../../../../utils/datepicker";
import { stringToEnum } from "../../../../liveops/screens/SaleEvent/utils";

type Props = {
  userId: string;
  teamId: string;
};

function validIfPrevDayOrAfter(selectedDate: moment.Moment) {
  return selectedDate.isSameOrAfter(moment().subtract(1, 'day').startOf('day'));
}

function zeroIfNegative(val: number): number {
  return val < 0 ? 0 : val;
}

function cappedValue(val: number, cap: number): number {
  const val_ = zeroIfNegative(val);
  return val_ > cap ? cap : val_;
}

const MAX_ITERATIONS = 9;
const MILESTONES_PER_ITER = 4;
const MAX_BOOST_SLOT_IDX = 4;

const AVAILABLE_BOOSTS: Array<CurrencyBoostType | string> = [
  'Inactive',
  CurrencyBoostType.ARTIST_ONLY,
  CurrencyBoostType.BOMB,
  CurrencyBoostType.NINJA,
  CurrencyBoostType.SPEEDSTER,
  CurrencyBoostType.REMOVE2,
];

export default function UserTeamDisplay({userId, teamId}: Props) {
  const [expanded, setExpanded] = useState(false);
  const noTeam = teamId.length === 0;

  const {data: teamData, loading: teamInfoLoading, refetch} = 
    useQuery<GetTeamHeatInfoQuery, GetTeamHeatInfoQueryVariables>(
    GET_TEAM_HEAT_INFO, {
      variables: {
        property: userId,
        type: UserSearchType.BY_ID,
      },
      skip: noTeam || !userId,
    },
  );

  const [updateTeamHeat, {loading: updateIsLoading}] = useMutation<
    UpdateTeamHeatInfoMutation,
    UpdateTeamHeatInfoMutationVariables
  >(UPDATE_TEAM_HEAT_INFO);

  const [updateTeamBoosts, {loading: updateTeamBoostLoading}] = useMutation<
    UpdateTeamBoostsMutation,
    UpdateTeamBoostsMutationVariables
  >(UPDATE_TEAM_BOOSTS);

  const [resetClaimedMilestones, {loading: resetMilestonesLoading}] = useMutation<
    ResetClaimedTeamHeatMilestonesMutation,
    ResetClaimedTeamHeatMilestonesMutationVariables
  >(RESET_CLAIMED_TEAM_HEAT_MILESTONES);

  const loading = 
    teamInfoLoading || 
    updateIsLoading || 
    updateTeamBoostLoading || 
    resetMilestonesLoading;
  const teamInfo = teamData?.user?.team;
  const teamHeatInfo = teamData?.user?.team?.teamHeat;
  const teamMonthlyPass = teamData?.user?.team?.teamMonthlyPass;
  const userMonthlyPass = teamData?.user?.currentUserMonthlyPass;

  const now_ = moment().toDate();

  const [iteration, setIteration] = useState(1);
  const [score, setScore] = useState(0);
  const [lastScoreUpdateTime, setLastScoreUpdateTime] = useState(now_);
  const [HeatModeExpire, setHeatModeExpire] = useState(now_);
  const [isInHeatMode, setIsInHeatMode] = useState(false);

  const [availableMileston, setAvailableMileston] = useState(0);

  const [availableBoost, setAvailableBoost] = useState(0);
  const [currentBoosts, setCurrentBoosts] = useState<Array<TeamHeatBoost>>([]);

  const [claimedMilestones, setClaimedMilestones] = useState<Array<number>>([]);

  const boostSlots = useMemo<Array<boolean>>(() => {
    return [0, 1, 2, 3, 4].map(val => availableBoost >= val);
  }, [availableBoost]);

  const hoursPassed = useMemo(() => {
    return moment().diff(lastScoreUpdateTime, 'hour', true).toPrecision(6);
  }, [lastScoreUpdateTime]);

  const claimedMilestoneText = useMemo(() => {
    return claimedMilestones.length > 0 
      ? claimedMilestones.toString()
      : 'Hasn\'t claimed any milestone yet.';
  }, [claimedMilestones]);

  useEffect(() => {
    if (teamHeatInfo && teamMonthlyPass && teamInfo) {
      setScore(teamHeatInfo.currentScore);
      setLastScoreUpdateTime(moment(teamHeatInfo.lastUpdateScoreTimestamp).toDate());
      setHeatModeExpire(moment(teamHeatInfo.maxHeatExpireTimestamp).toDate());
      setIsInHeatMode(teamHeatInfo.isMaxHeat);

      setIteration(teamMonthlyPass.teamHeatIteration + 1);
      setAvailableMileston(teamMonthlyPass.availableTeamHeatMilestone + 1);

      setAvailableBoost(teamInfo.availableBoosts);
      const fakeDate = moment().subtract(2, 'hours').toDate();
      setCurrentBoosts(teamInfo.boosts.map((val) => {
        if (!val) {
          return {
            __typename: "TeamHeatBoost",
            type: CurrencyBoostType.REMOVE2,
            expireTimestamp: fakeDate,
          };
        }
        return val;
      }));
    }
    if (userMonthlyPass) {
      setClaimedMilestones(userMonthlyPass.claimedTeamHeatMilestone.map(val => val + 1));
    }
  }, [teamHeatInfo, teamMonthlyPass, teamInfo, userMonthlyPass]);

  const handleSaveTeamHeat = () => {
    updateTeamHeat({
      variables: {
        input: {
          teamId,
          iteration: iteration - 1,
          currentScore: score,
          availableMilestoneIdx: availableMileston - 1,
          lastUpdateScoreTimestamp: lastScoreUpdateTime,
          maxHeatExpireTimestamp: HeatModeExpire,
        }
      }
    });
  };

  const resetTeamHeatInfo = useCallback(() => {
    const now2 = moment().subtract(2, 'day').toDate();
    updateTeamHeat({
      variables: {
        input: {
          teamId,
          iteration: 0,
          currentScore: 0,
          availableMilestoneIdx: -1,
          lastUpdateScoreTimestamp: now2,
          maxHeatExpireTimestamp: now2,
        }
      }
    });
  }, [updateTeamHeat, teamId]);

  const handleSaveTeamBoosts = useCallback(() => {
    updateTeamBoosts({
      variables: {
        input: {
          teamId,
          availableBoosts: availableBoost,
          boosts: currentBoosts.map(val => ({type: val.type, expireTimestamp: val.expireTimestamp})),
        }
      }
    });
  }, [updateTeamBoosts, teamId, availableBoost, currentBoosts]);

  const handleResetClaimedMilestones = useCallback((onlyForMe: boolean) => {
    resetClaimedMilestones({
      variables: {
        input: {
          teamId,
          onlyForMe
        },
      }
    }).then((value) => {
      if (value?.data) {
        setClaimedMilestones(
          value.data.resetClaimedTeamHeatMilestones.userMonthlyPass.claimedTeamHeatMilestone.map(val => val + 1)
        );
      }
    });
  }, [resetClaimedMilestones, teamId]);

  const handleNumberChange = useCallback((
    setter: (val: number) => void, 
    validator: (val: number) => number, 
    event
  ) => {
    const number = !Number.isNaN(+event.target.value) ? +event.target.value : 0;
    setter(validator(number));
  }, []);

  const handleActiveBoostTypeChanged = useCallback((
    slotIndex: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const val: string = event.target.value;
    if (val === AVAILABLE_BOOSTS[0]) {
      return;
    }
    const now__ = moment();
    const boostType = (val as CurrencyBoostType);
    const isTypeAlreadyActive = currentBoosts.some(
      boost => boost.type === boostType && moment(boost.expireTimestamp).diff(now__) > 0);

    if (isTypeAlreadyActive) {
      return;
    }

    const newCurrentBoosts: Array<TeamHeatBoost> = [...currentBoosts];
    const activeDate = moment(newCurrentBoosts[slotIndex].expireTimestamp);
    const wasInactive = activeDate.diff(moment()) < 0;
    const newDate = wasInactive ? now__.add(30, 'minute') : activeDate;
    
    newCurrentBoosts[slotIndex] = {...newCurrentBoosts[slotIndex], expireTimestamp: newDate, type: boostType };
    setCurrentBoosts(newCurrentBoosts);
  }, [currentBoosts]);

  const handleActiveBoostTimestampChanged = useCallback(
    (slotIndex: number, date: string | moment.Moment) => {
      const newCurrentBoosts = [...currentBoosts];
      const newDate = moment(date).toDate();
      newCurrentBoosts[slotIndex] = {...newCurrentBoosts[slotIndex], expireTimestamp: newDate };
      setCurrentBoosts(newCurrentBoosts);
  }, [currentBoosts]);

  const handleHeatExpirationChanged = useCallback((date: moment.Moment) => {
    if (date.diff(lastScoreUpdateTime) < 0) {
      setLastScoreUpdateTime(date.clone().subtract(1, 'minute').toDate());
    }
    if (!isInHeatMode && date.diff(moment()) > 0) {
      setAvailableMileston(iteration * 4);
    } else if (isInHeatMode && date.diff(moment()) < 0) {
      setAvailableMileston((iteration - 1) * 4);
    }
    setHeatModeExpire(date.toDate());
  }, [isInHeatMode, iteration, lastScoreUpdateTime]);

  return (
    <Card className="mb-4">
      <Card.Body>
        <Accordion>
          <Accordion.Toggle as={Card.Title} variant="link" eventKey="0" onClick={() => setExpanded(!expanded)}>
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              Team
              <Button variant="link">
                {!expanded && <i className="fas fa-chevron-down" />}
                {expanded && <i className="fas fa-chevron-up" />}
              </Button>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            {noTeam ? (
              <Row className="mb-3">
                <Col md={2}>User has no team yet.</Col>
              </Row>
            ) : (
              loading ? 
              <LoadingLogo show={loading} />
              :
              <>
                <Row className="mb-3">
                  <Col md={1}>Team Id:</Col>
                  <Col md={3}>{teamId}</Col>
                  <Button onClick={() => refetch()}>
                    <i className="fas fa-redo"/>
                  </Button>
                </Row>
                <br />
                <h5>
                  Team Boosts
                </h5>
                  
                <Row className="mb-3">
                  <Col md='auto'>Available boost slots:</Col>
                  <Col md='auto'>
                    <Form.Control
                      type="text"
                      value={availableBoost + 1}
                      onChange={(event) => handleNumberChange(
                        setAvailableBoost, (val) => cappedValue(val - 1, MAX_BOOST_SLOT_IDX),
                        event
                      )} />
                  </Col>
                  <Col md='auto'>Active boosts:</Col>
                  <Col md='auto'>
                    {boostSlots.map((value, index) => {
                      const isSlotAvailable = value;
                      const slot = currentBoosts[index];
                      const boostInSlot = isSlotAvailable && slot ? slot.type : 'Inactive';
                      const timestamp = slot ? slot.expireTimestamp : now_;
                      const timeleftMinutes = moment(timestamp).diff(now_, 'minute');
                      const timeleftSeconds = moment(timestamp).diff(now_, 'seconds') - (timeleftMinutes * 60);
                      const isActive = isSlotAvailable && timeleftSeconds > 0;
                      const add30Timestamp = moment(isActive ? timestamp : now_).add(30, 'minute');
                      return (
                        <Row className="mb-3" key={index}>
                          <Col md='auto'>
                            <Form.Control
                              as="select"
                              type="text"
                              value={boostInSlot}
                              disabled={!isSlotAvailable}
                              onChange={(event) => handleActiveBoostTypeChanged(index, event)} >
                              {AVAILABLE_BOOSTS.map((option) => (
                                <option key={option} value={stringToEnum(option, CurrencyBoostType)}>
                                  {option}
                                </option>
                              ))}
                            </Form.Control>
                          </Col>
                          <Col md='auto'>
                            <DateTimePicker 
                              timeFormat={true}
                              onChange={(val) => handleActiveBoostTimestampChanged(index, val)}
                              value={timestamp}
                            />
                          </Col>
                          {isSlotAvailable && (<Col md='auto'>
                            <Button 
                              className='align-self-center ml-1'
                              onClick={() => handleActiveBoostTimestampChanged(index, add30Timestamp)}>
                              Add 30 mins
                            </Button>
                          </Col>)}
                          {isActive && (
                            <Col md='auto'>
                              Time left: {timeleftMinutes} min {timeleftSeconds} sec
                            </Col>
                          )}
                        </Row>
                      );
                    })}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Button className='align-self-center ml-1' onClick={handleSaveTeamBoosts}>
                    Save Changes (team boosts)
                  </Button>
                </Row>

                <br />
                <h5>
                  Team Heat
                </h5>
                <Row className="mb-3">
                  <Col md='auto'>Iteration:</Col>
                  <Col md='auto'>
                    <Form.Control
                      type="text"
                      value={iteration}
                      onChange={(event) => handleNumberChange(
                        setIteration, (val) => cappedValue(val, MAX_ITERATIONS + 1),
                        event
                      )} />
                  </Col>
                  <Col md="auto">Score:</Col>
                  <Col md='auto'>
                  <Form.Control
                    type="text"
                    value={score}
                    onChange={(event) => handleNumberChange(setScore, (val) => zeroIfNegative(val), event)} />
                  </Col>
                  <Col md="auto">Last Update Score:</Col>
                  <Col md='auto'>
                      {teamHeatInfo?.lastUpdateScore}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={3}>Last update timestamp</Col>
                  <Col md="auto">
                    <DateTimePicker 
                      timeFormat={true}
                      onChange={applyChangeIfDateValid(setLastScoreUpdateTime)}
                      isValidDate={validIfPrevDayOrAfter}
                      value={lastScoreUpdateTime}
                    />
                  </Col>
                  <Col md="auto">Hours passed:</Col>
                  <Col md='auto'>{hoursPassed}</Col>
                </Row>
                <Row className="mb-3">
                  <Col md="auto">Is in heat mode</Col>
                  <Form.Check type="checkbox" checked={isInHeatMode} />
                </Row>
                <Row className="mb-3">
                  <Col md={3}>Team heat mode expire timestamp</Col>
                  <Col md="auto">
                    <DateTimePicker 
                      timeFormat={true}
                      onChange={applyChangeIfDateValid(setHeatModeExpire)}
                      value={HeatModeExpire}
                      isValidDate={validIfPrevDayOrAfter}
                    />
                  </Col>
                  <Col md='auto'>
                    <Button 
                      className='align-self-center ml-1'
                      onClick={() => handleHeatExpirationChanged(moment(isInHeatMode ? HeatModeExpire : now_).add(1, 'hour'))}>
                      Add 1 hour
                    </Button>
                  </Col>
                  <Col md='auto'>
                    <Button 
                      className='align-self-center ml-1'
                      onClick={() => handleHeatExpirationChanged(moment(isInHeatMode ? HeatModeExpire : now_).add(1, 'day'))}>
                      Add 1 day
                    </Button>
                  </Col>
                  <Col md='auto'>
                    <Button 
                      className='align-self-center ml-1'
                      variant="danger"
                      onClick={() => handleHeatExpirationChanged(moment().subtract(1, 'minute'))}>
                      Reset Heat Mode
                    </Button>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md='auto'>Available Team Heat milestone:</Col>
                  <Col md='auto'>
                    <Form.Control 
                      type="text"
                      value={availableMileston}
                      onChange={(event) => handleNumberChange(
                        setAvailableMileston, 
                        (val) => cappedValue(val, MAX_ITERATIONS * MILESTONES_PER_ITER + 1), 
                        event
                      )} />
                  </Col>
                  <Col md='auto'>
                      In range [1, {MAX_ITERATIONS * MILESTONES_PER_ITER + 1}]
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md='auto'>Claimed Team Heat milestones:</Col>
                  <Col md='auto'>{claimedMilestoneText}</Col>
                </Row>
                <Row className="mb-3">
                  <Button className='align-self-center mr-5' onClick={handleSaveTeamHeat}>
                    Save Changes (team heat)
                  </Button>
                  <Button  className='mx-5' variant="danger" onClick={resetTeamHeatInfo}>
                    Reset Team Heat
                  </Button>
                  <Button className='align-self-center mx-5' onClick={() => handleResetClaimedMilestones(true)}>
                    Reset claimed rewards for me
                  </Button>
                  <Button  className='mx-5' variant="danger" onClick={() => handleResetClaimedMilestones(false)}>
                    Reset claimed rewards for all team members
                  </Button>
                </Row>
              </>
            )}  
          </Accordion.Collapse>
        </Accordion>
      </Card.Body>
    </Card>
  );
}