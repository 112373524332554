import Cache from './screens/Cache/cache';
import GraphiQLView from './screens/GraphiQLView';
import GraphQLVoyagerView from './screens/GraphQLVoyagerView';
import Autocompletion from './screens/Hurdle/Autocompletion';
import InputView from './screens/InputView';
import StacktraceView from './screens/StacktraceView';
import UserView from './screens/UserView';
import MassGift from './screens/MassGift';

export const PREFIX = 'sp3';

export default [
  {
    path: `/${PREFIX}/devtools/table`,
    component: InputView,
    name: 'Data Look Up',
  },
  {
    path: `/${PREFIX}/devtools/userview/:userid?`,
    name: 'User View',
    component: UserView,
  },
  {
    path: `/${PREFIX}/devtools/stacktrace/`,
    name: 'Stacktrace',
    component: StacktraceView,
  },
  {
    path: `/${PREFIX}/devtools/autocompletion/`,
    name: 'Song Autocompletion Test',
    component: Autocompletion,
  },
  {
    path: `/${PREFIX}/devtools/caches/`,
    name: 'Cache tools',
    component: Cache,
  },
  {
    path: `/${PREFIX}/devtools/graphql`,
    name: 'GraphQL',
    component: GraphiQLView,
  },
  {
    path: `/${PREFIX}/devtools/graphql-viz`,
    name: 'GraphQL Vizualizer',
    component: GraphQLVoyagerView,
  },
  {
    path: `/${PREFIX}/devtools/massgift`,
    name: 'Mass Gifting',
    component: MassGift,
  },
];
