import React from 'react';
import Alert from 'react-bootstrap/Alert';

const ONE_TB = 1000000000000;
function preciseWithCents(x) {
  return Number.parseFloat(x).toPrecision(2);
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const value = parseFloat((bytes / k ** i).toFixed(dm));

  return `${value} ${sizes[i]}`;
}

const CostDisplay = ({ metadata }) => {
  console.log('CostDisplay', metadata);
  const totalBytes = Object.values(metadata).reduce(
    (sum, item) => sum + parseInt(item.query.totalBytesBilled ?? 0, 10),
    0
  );
  const maxBytes = Object.keys(metadata).reduce(
    (acc, queryId) => {
      if (metadata[queryId].query.totalBytesBilled > acc[1]) {
        return [queryId, metadata[queryId].query.totalBytesBilled];
      }
      return acc;
    },
    [null, 0]
  );

  const cacheHits = Object.keys(metadata).reduce((acc, queryId) => {
    if (metadata[queryId].query.cacheHit) {
      acc.push(queryId);
    }
    return acc;
  }, []);

  // const cacheHits = Object.values(metadata).filter((item) => item.query.cacheHit).length;

  const finalPrice = preciseWithCents((totalBytes / ONE_TB) * 6.25);
  return (
    <Alert variant="info">
      {formatBytes(totalBytes)} billed ({finalPrice}$)
      {totalBytes > 0 && (
        <>
          <br />
          Worst performing query: {maxBytes[0]} ({((100 * maxBytes[1]) / totalBytes).toLocaleString()}% of budget)
        </>
      )}
      <br />
      cacheHits: {cacheHits.length}/{Object.values(metadata).length} ({cacheHits.join(', ')})
    </Alert>
  );
};

export default CostDisplay;
