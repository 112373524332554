import { gql } from '@apollo/client';

export const GET_STICKERS = gql`
  query getStickers {
    liveOps {
      stickers {
        list {
          id
          name
          cost {
            ... on CurrencyCoin {
              id
              amount
              __typename
            }
            ... on CurrencyDiamond {
              id
              amount
              __typename
            }
            ... on CurrencyCategoryFragment {
              id
              amount
              category {
                id
              }
              __typename
            }
          }
          liveOpsAvailability
          asset
          nextLevelItems {
            id
            level
            cost {
              amount
            }
            asset
          }
        }
      }
    }
  }
`;
export const GET_SKINS = gql`
  query getSkins {
    liveOps {
      appskins {
        list {
          id
          name
          iconAsset
          iconPrimaryColor
          iconBackgroundColor
          cost {
            ... on CurrencyCoin {
              id
              amount
              __typename
            }
            ... on CurrencyDiamond {
              id
              amount
              __typename
            }
            ... on CurrencyCategoryFragment {
              id
              amount
              category {
                id
              }
              __typename
            }
          }
          liveOpsAvailability
          asset
          nextLevelItems {
            id
            level
            cost {
              amount
            }
            asset
          }
        }
      }
    }
  }
`;
export const GET_FRAMES = gql`
  query getFrames {
    liveOps {
      profileFrames {
        list {
          id
          name
          cost {
            ... on CurrencyCoin {
              id
              amount
              __typename
            }
            ... on CurrencyDiamond {
              id
              amount
              __typename
            }
            ... on CurrencyCategoryFragment {
              id
              amount
              category {
                id
              }
              __typename
            }
          }
          liveOpsAvailability
          asset
          nextLevelItems {
            id
            level
            cost {
              amount
            }
            asset
          }
        }
      }
    }
  }
`;

export const UPDATE_ITEM = gql`
  mutation updateItem($input: UpdateItemInput!) {
    updateItem(input: $input) {
      item {
        id
        name
      }
    }
  }
`;

export const CREATE_ITEM = gql`
  mutation createItem($input: CreateItemInput!) {
    createItem(input: $input) {
      item {
        id
        name
      }
    }
  }
`;

export const UPLOAD_ASSET = gql`
  mutation uploadAsset($input: UploadAssetInput!) {
    uploadAsset(input: $input) {
      item {
        id
        name
        asset
      }
    }
  }
`;

export const UPLOAD_ICON_ASSET = gql`
  mutation uploadIconAsset($input: UploadIconAssetInput!) {
    uploadIconAsset(input: $input) {
      item {
        id
        name
        asset
      }
    }
  }
`;

export const UPLOAD_SHEET = gql`
  mutation uploadSheet($input: UploadSheetInput!) {
    uploadSheet(input: $input) {
      message
    }
  }
`;

export const MISSION_FRAGMENT = gql`
  fragment MissionFragment on Mission {
    id
    start
    end
    requiredTier
    tasks {
      type
      params {
        ... on MissionParamInt {
          intValue: value
        }
        ... on MissionParamHour24 {
          intValue: value
        }
        ... on MissionParamFloat {
          floatValue: value
        }
        ... on MissionParamString {
          stringValue: value
        }
        ... on MissionParamSong {
          variantIds
        }
        ... on MissionParamContentPack {
          contentPack {
            id
          }
        }
        ... on MissionParamContentCategory {
          category {
            id
          }
        }
      }
    }
    rewardAccolades
    rewardXp
    rewardDiamonds
    rewardCoins
    reward {
      ... on Currency {
        amount
      }

      ... on CurrencyBoost {
        amount
        boostType
      }
    }
  }
`;

export const MONTHLY_PASS_REWARD_FRAGMENT = gql`
  fragment MonthlyPassRewardFragment on LevelReward {
    ... on Currency {
      amount
      __typename
    }
    ... on CurrencyCategoryFragment {
      amount
      category {
        id
        icon
        name
      }
    }
    ... on CurrencyBoost {
      amount
      boostType
      __typename
    }
    ... on Item {
      __typename
      id
    }
    ... on MonthlyBuff {
      __typename
      multiplier
      buffType
    }
    ... on ContentPackReward {
      __typename
      contentPack {
        id
        name
      }
    }
  }
`;

export const GET_MONTHLY_PASSES = gql`
  query getMonthlyPasses {
    liveOps {
      id
      monthlyPasses(limit: 10, offset: 0) {
        list {
          id
          monthId
          month
          year
          levels {
            level
            xpToNextLevel
            basicReward {
              ...MonthlyPassRewardFragment
            }
            plusReward {
              ...MonthlyPassRewardFragment
            }
          }
          teamRankGroups {
            id
            minRank
            maxRank
            rewards {
              ...MonthlyPassRewardFragment
            }
          }
          teamHeatIterations {
            milestonesReward {
              ...MonthlyPassRewardFragment
            }
          }
          missions {
            id
            dailyMissions {
              ...MissionFragment
            }
            monthlyMissions {
              ...MissionFragment
            }
            extraMissions {
              ...MissionFragment
            }
            teamMissions {
              ...MissionFragment
            }
          }
        }
      }
    }
  }
  ${MISSION_FRAGMENT}
  ${MONTHLY_PASS_REWARD_FRAGMENT}
`;
export const UPDATE_MONTHLY_PASS = gql`
  mutation updateMonthlyPass($input: UpdateMonthlyPassInput!) {
    updateMonthlyPass(input: $input) {
      monthlyPass {
        id
        levels {
          level
        }
      }
    }
  }
`;
export const INIT_MONTHLY_PASS_MISSIONS = gql`
  mutation initMonthlyPassMissions($input: InitMonthlyPassMissionsInput!) {
    initMonthlyPassMissions(input: $input) {
      missions {
        id
      }
    }
  }
`;

export const UPDATE_MONTHLY_PASS_MISSIONS = gql`
  mutation updateMonthlyPassMissions($input: UpdateMonthlyPassMissionsInput!) {
    updateMonthlyPassMissions(input: $input) {
      missions {
        id
      }
    }
  }
`;

export const UPDATE_TEAM_HEAT_ITERATION = gql`
  mutation updateTeamHeatIterationMilestones($input: TeamHeatIterationInput!) {
    updateTeamHeatIterationMilestones(input: $input) {
      monthlyPass {
        teamHeatIterations {
          milestonesReward {
            ...MonthlyPassRewardFragment
          }
        }
      }
    }
  }
  ${MONTHLY_PASS_REWARD_FRAGMENT}
`;

export const GET_CONTENT_CATEGORIES = gql`
  query contentCategories {
    app {
      contentCategories {
        id
        name
        icon
      }
    }
  }
`;

export const GET_LIVE_SHOW_PRESETS = gql`
  query quizLiveShowPresets {
    liveOps {
      quizLiveShowPresets {
        id
        description
      }
    }
  }
`;

export const SEARCH_CONTENT_PACKS_BY_CATEGORY = gql`
  query contentPacksByCategory($category: ID!, $excludeExplicit: Boolean!, $limit: Int, $offset: Int) {
    liveOps {
      contentPacksByCategory(category: $category, excludeExplicit: $excludeExplicit, limit: $limit, offset: $offset) {
        id
        name
      }
    }
  }
`;

export const GET_ITEMS = gql`
  query getItems {
    liveOps {
      stickers {
        list {
          id
          name
          liveOpsAvailability
        }
      }
      appskins {
        list {
          id
          name
          liveOpsAvailability
        }
      }
      profileFrames {
        list {
          id
          name
          liveOpsAvailability
        }
      }
    }
  }
`;

export const CREATE_MONTHLY_PASS = gql`
  mutation createMonthlyPass {
    createMonthlyPass {
      monthlyPass {
        id
      }
    }
  }
`;
export const ITEM_FRAGMENT = gql`
  fragment ItemFragment on Item {
    id
    name
    cost {
      amount
      __typename
    }
    asset
    level
    availability
    ... on AppSkin {
      iconAsset
      iconPrimaryColor
      iconBackgroundColor
    }
  }
`;

export const VANITY_ITEM_BY_ID_QUERY = gql`
  query getVanityItem($property: String!, $type: SearchType!) {
    item(property: $property, type: $type) {
      ...ItemFragment
    }
  }
  ${ITEM_FRAGMENT}
`;

const BADGE_FRAGMENT = gql`
  fragment BadgeConfigFragment on BadgeConfig {
    id
    type
    name
    unlockReq
    levelUpReq
    details
  }
`;

export const GET_BADGES = gql`
  query getBadges {
    liveOps {
      badgeConfigs {
        hasMore
        totalCount
        list {
          ...BadgeConfigFragment
        }
      }
    }
  }
  ${BADGE_FRAGMENT}
`;

export const CREATE_BADGE = gql`
  mutation createBadge($input: CreateBadgeInput!) {
    createBadge(input: $input) {
      badge {
        ...BadgeConfigFragment
      }
    }
  }
  ${BADGE_FRAGMENT}
`;

export const UPDATE_BADGE = gql`
  mutation updateBadge($input: UpdateBadgeInput!) {
    updateBadge(input: $input) {
      badge {
        ...BadgeConfigFragment
      }
    }
  }
  ${BADGE_FRAGMENT}
`;

export const UPLOAD_BADGE_ASSET = gql`
  mutation uploadBadgeAssets($input: UploadBadgeAssetInput!) {
    uploadBadgeAssets(input: $input) {
      asset
    }
  }
`;

export const DELETE_BADGE = gql`
  mutation deleteBadge($input: RemoveBadgeInput!) {
    removeBadge(input: $input) {
      badgeDeleted
    }
  }
`;

export const SPECIAL_BADGE_UNLOCK_LEVELUP_REQ_CONFIG = gql`
  query getSpecialBadgeUnlockAndLevelUpRequirementsConfig {
    liveOps {
      badgeLevelUpReqConfig
      badgeUnlockReqConfig
    }
  }
`;
