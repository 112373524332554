import { LevelRewardInput, LevelRewardInputType } from '../../../../__gqltypes__';

export const typeEmoji = {
  CURRENCY_DIAMOND: '💎 ',
  CURRENCY_TOURNAMENT_TICKET: '🎟️ ',
  CURRENCY_COIN: '💰 ',
  XP: '🏃‍♂️ ',
  STICKER: '🎇 ',
  APP_SKIN: '💿 ',
  PROFILE_FRAME: '🖼️ ',
  BADGE: '',
  CURRENCY_CATEGORY_FRAGMENT: '🧩 ',
  CURRENCY_BOOST: '🔥 ',
  CONTENT_PACK_REWARD: '🎶',
  CURRENCY_SPECIAL_EVENT: '✳️',
  CURRENCY_META_FRAGMENT_BRONZE: '🥉',
  CURRENCY_META_FRAGMENT_SILVER: '🥈',
  CURRENCY_META_FRAGMENT_GOLD: '🥇',
  CURRENCY_SHARD_BRONZE: '🟤',
  CURRENCY_SHARD_SILVER: '⚪',
  CURRENCY_SHARD_GOLD: '🟡',
  CURRENCY_ACCOLADE: '💠',
  CURRENCY_SUBSCRIPTION_DAY: '🚀',
};
// not clean ... (itemName)
export const formatReward = (reward?: LevelRewardInput & { itemName?: string }, displayCategory = false) => {
  if (!reward) {
    return '';
  }
  switch (reward.type) {
    case 'CURRENCY_COIN':
    case 'CURRENCY_SPECIAL_EVENT':
    case 'CURRENCY_DIAMOND':
    case 'CURRENCY_TOURNAMENT_TICKET':
    case 'CURRENCY_META_FRAGMENT_BRONZE':
    case 'CURRENCY_META_FRAGMENT_SILVER':
    case 'CURRENCY_META_FRAGMENT_GOLD':
    case 'CURRENCY_SHARD_BRONZE':
    case 'CURRENCY_SHARD_SILVER':
    case 'CURRENCY_SHARD_GOLD':
    case 'CURRENCY_ACCOLADE':
    case 'CURRENCY_SUBSCRIPTION_DAY':
      return `${typeEmoji[reward.type]} ${reward.amount} ${reward.type}`;
    case 'CURRENCY_CATEGORY_FRAGMENT': {
      if (reward.itemId === null) {
        return `${typeEmoji[reward.type]} ${reward.amount} Random ${reward.type}`;
      }
      return `${typeEmoji[reward.type]} ${reward.amount} ${reward.itemId} ${reward.type}`;
    }
    case 'BADGE':
    case 'STICKER':
    case 'PROFILE_FRAME':
    case 'APP_SKIN':
      return `${typeEmoji[reward.type]} ${reward.itemId} ${reward.type}`;
    case 'MONTHLY_BUFF':
      return `${reward.itemId === 'COIN' ? typeEmoji.CURRENCY_COIN : typeEmoji.XP} ${reward.itemId} buff of ${
        reward.amount
      }`;
    case 'CURRENCY_BOOST':
      return `${typeEmoji[reward.type]} ${reward.amount} ${reward.itemId} boosts`;
    case 'CONTENT_PACK_REWARD':
      return `${typeEmoji[reward.type]} ${reward.itemName ?? ''} (${reward.itemId}) ${reward.type}`;
    default:
      return 'Unknown Reward Type';
  }
};

export const REWARDS_MAP: Record<LevelRewardInputType, null | string | { id: string; name: string }[]> = {
  CURRENCY_COIN: null,
  CURRENCY_DIAMOND: null,
  CURRENCY_TOURNAMENT_TICKET: null,
  CURRENCY_BOOST: [
    { id: 'REMOVE2', name: 'Remove two' },
    { id: 'TEAM_SPIRIT', name: 'Team spirit' },
    { id: 'REVIVE', name: 'Revive' },
    { id: 'HURDLE_HINT', name: 'Tuneology hint' },
    { id: 'BOMB', name: 'Bomb' },
    { id: 'ARTIST_ONLY', name: 'Artist only' },
    { id: 'SPEEDSTER', name: 'Speedster' },
    { id: 'NINJA', name: 'Ninja' },
  ],
  CURRENCY_CATEGORY_FRAGMENT: [
    { id: 'RANDOM', name: 'Random' },
    { id: 'tournament', name: 'Tournament (can only be used in Tournament milestones)' },
  ], // If random, the BE will automatically select the correct category. If tournament, we explicitly set it to tournament
  STICKER: 'stickers',
  PROFILE_FRAME: 'profileFrames',
  APP_SKIN: 'appSkins',
  BADGE: 'badges',
  MONTHLY_BUFF: [
    { id: 'XP', name: 'XP' },
    { id: 'COIN', name: 'Coin' },
  ],
  CONTENT_PACK_REWARD: 'contentPackReward',
  CURRENCY_SPECIAL_EVENT: null,
  CURRENCY_META_FRAGMENT_BRONZE: null,
  CURRENCY_META_FRAGMENT_SILVER: null,
  CURRENCY_META_FRAGMENT_GOLD: null,
  CURRENCY_SHARD_BRONZE: null,
  CURRENCY_SHARD_SILVER: null,
  CURRENCY_SHARD_GOLD: null,
  CURRENCY_ACCOLADE: null,
  CURRENCY_SUBSCRIPTION_DAY: null,
};
