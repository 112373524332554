import _ from "lodash";
import React, { useCallback, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

import { GiftFilters, UserGender, UserSubscriptionTier } from "../../../../../__gqltypes__";
import { 
  BaseSelectorSlicer,
  DefaultContinentSlicer,
  DefaultCountrySlicer,
  DefaultIsSpenderSlicer,
  DefaultPlatformSlicer,
  DefaultVipStatusSlicer,
  NumberSlicer,
  TextSlicer
} from "../../../../../utils/dashboards/components/slicers";


type Props = {
  onChange: (newFilters: GiftFilters) => void,
  onRefresh: () => void,
}
export const MassGiftFilters = (props: Props): JSX.Element => {
  const [filters, setFilters] = useState<GiftFilters>({});
  const {onChange, onRefresh} = props;

  const onReset = useCallback(() => {
      setFilters({});
      onChange({});
  }, [onChange]);

  const onUpdateFilter = (
    value: string,
    key: string,
    processor: (val: string) => number | string | null | string[]
  ) => {
    const processedVal = processor(value);
    const newFilters = {...filters, [key]: processedVal};
    setFilters(newFilters);
    onChange(newFilters);
  };

  const stringProcessor = useCallback((val: string): string | null => {
    return val === 'All' || val === 'ALL' ? null : val;
  }, []);

  const numProcessor = useCallback((val: string): number | null => {
    const num = +val;
    return num <= 0 || Number.isNaN(num) ? null : num;
  }, []);

  const arrProcessor = useCallback((val: string) => {
    return val.length > 0 ? val.split(',') : null;
  }, []);

  const genderOptions = Object.keys(UserGender).map((val) => ({
    text: val,
    value: val,
  }));
  
  const spenderVal = _.isNil(filters.isSpender)
    ? 'All'
    : filters.isSpender > 0
      ? 'spender'
      : 'non spender';
  const userIds = _.isNil(filters.specificUsers) ? '': filters.specificUsers.join(',');
  return (
    <Card className="m-2 border border-secondary">
      <Card.Body>
        <Form>
          <TextSlicer 
            title="App Version"
            id="version" 
            value={filters.appVersion ?? ''} 
            defaultValue="003.020.000" onChange={(val) => onUpdateFilter(val ?? '', 'appVersion', stringProcessor)} />
          <DefaultPlatformSlicer
            title="Platform"
            id="platform"
            value={filters.platform}
            onChange={(val) => onUpdateFilter(val, 'platform', stringProcessor)}/>
          <DefaultContinentSlicer 
            title="Region $"
            id="region"
            value={filters.region}
            onChange={(val) => onUpdateFilter(val, 'region', stringProcessor)}/>
          <DefaultCountrySlicer
            title="Country $"
            id="country"
            value={filters.country}
            onChange={(val) => onUpdateFilter(val, 'country', stringProcessor)} />
          <Row className="mb-3 justify-content-between">
            <Col md={3}>
              User Subscription Status:
            </Col>
            <Col md={6} className="align-self-end">
              <Form.Control
                as="select"
                value={filters.subTier ?? 'All'}
                type="text"
                onChange={(e) => onUpdateFilter(e.target.value, 'subTier', stringProcessor)}
              >
                <option>All</option>
                {Object.keys(UserSubscriptionTier).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
          <BaseSelectorSlicer
            title="Gender"
            id="gender"
            value={filters.gender}
            options={genderOptions}
            onChange={(val) => onUpdateFilter(val, 'gender', stringProcessor)}/>
          <NumberSlicer
            title="Age"
            id="age"
            value={filters.age?.toString()}
            onChange={(val) => onUpdateFilter(val, 'age', numProcessor)}/>
          <NumberSlicer
            title="Days since last online"
            id="daysSinceLastOnline"
            value={filters.daysSinceLastOnline?.toString()}
            onChange={(val) => onUpdateFilter(val, 'daysSinceLastOnline', numProcessor)}/>
          <NumberSlicer 
            title="Days since first install"
            id="daysSinceInstall"
            value={filters.daysSinceInstall?.toString()}
            onChange={(val) => onUpdateFilter(val, 'daysSinceInstall', numProcessor)}/>
          <DefaultIsSpenderSlicer
            title="Is spender $"
            id="isSpender"
            value={spenderVal}
            onChange={(val) => {
              const newFilters = {...filters};
              newFilters.isSpender = val === 'spender' ? 1 : 0;
              onChange(newFilters);
              setFilters(newFilters);}
            }/>
          <Row className="mb-3 justify-content-between">
            <Col md={3}>
              User Ids: <br />
              (separated by commas &quot;,&quot;)
            </Col>
            <Col md={6} className="align-self-end">
              <Form.Control
                as="textarea"
                value={userIds}
                onChange={(e) => onUpdateFilter(e.target.value, 'specificUsers', arrProcessor)}
              />
            </Col>
          </Row>
          <Row className="justify-content-start ml-1">
            <Button onClick={onRefresh} style={{marginRight: 10}}>
              <i className="fas fa-redo mr-2" />
              Refresh
            </Button>
            <Button onClick={onReset}>
              <i className="fas fa-redo mr-2" />
              Reset
            </Button>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};
